import React, { Fragment } from 'react';
import { Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import arrayMove from 'array-move';

import firebase from '../../firebase';
import { fields, } from '../../shared/models/customSegment';
import { batch } from '../../shared/firebase';
import { segmentTypes, } from '../../shared/config';
import { canWriteCustomSegments } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import HelpLink from '../HelpLink';
import ShareButton from '../ShareButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ModelFormModal from '../modals/ModelFormModal';
import CompanyPage from '../hocs/CompanyPage';

const { entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(CompanyCustomSegments);

function CompanyCustomSegments(props) {
  const { company, user, members, match: { params: { companyId } } } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const { segmentType = 'segment1', } = queryParams;
  const { collectionName, freeeCollectionName, } = segmentTypes[segmentType];
  const [showsModal, toggleModal] = useToggle();
  const customSegments = useCollectionSubscription(company.ref.collection(collectionName).orderBy('createdAt'), [companyId, segmentType]);
  const freeeSegments = useCollectionSubscription(company.ref.collection(freeeCollectionName), [companyId, segmentType]);
  const sortedItems = sortBy(customSegments, _ => _.index != null ? _.index : customSegments.indexOf(_));
  const allFreeeSegments = [{ id: 0, name: '未選択' }, ...freeeSegments];
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMove(sortedItems, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection(collectionName).doc(id), { index });
    });
  };
  const toggleTab = (segmentType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, segmentType, }));
  };

  return (
    <div className="company-custom-segments">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="カスタムセグメントを作成する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>カスタムセグメント</h3>
        </div>
        <Nav tabs>
          {
            entries(segmentTypes).map(([k, { label }]) => {
              return (
                <NavItem key={k}>
                  <NavLink className={classnames('cursor-pointer', { active: segmentType === k })} onClick={toggleTab.bind(this, k)}>
                    {label}
                  </NavLink>
                </NavItem>
              )
            })
          }
        </Nav>
        <div className="mt-5 d-flex justify-content-end gap-1">
          {
            canWriteCustomSegments(user, companyId) && (
              <Fragment>
                <BatchButton {...{ collectionName, company, freeeSegments, customSegments, }} />
                <AddButton itemRef={company.ref.collection(collectionName).doc()} FormModal={ModelFormModal} formProps={{ title: 'カスタムセグメント 追加', fields: fields({ freeeSegments: allFreeeSegments, customSegments, companyMembers: members, }), }} />
              </Fragment>
            )
          }
        </div>
        {
          customSegments.length > 0 ? (
            <table className="table table-hover mt-5">
              <thead>
                <tr>
                  <th></th>
                  <th style={{ minWidth: 150 }}>セグメント名</th>
                  <th style={{ minWidth: 150 }}>freeeセグメント</th>
                  <th></th>
                </tr>
              </thead>
              <Container
                dragHandleSelector=".drag-handle"
                onDrop={onDrop}
                onDragStart={_ => toggleDragging(true)}
                onDragEnd={_ => toggleDragging(false)}
                dropPlaceholder={{ style: { background: 'eee', } }}
                render={(ref) => (
                  <tbody ref={ref}>
                    {
                      sortedItems.map((customSegment) => {
                        const { id, ref, name, segmentIds = [], } = customSegment;
                        return (
                          <Draggable
                            key={id}
                            render={() => (
                              <tr style={{ display: !isDragging && 'table-row' }}>
                                <td style={{ width: 30 }}>
                                  <div className="drag-handle text-muted cursor-pointer">
                                    <span className="fas fa-grip-vertical" />
                                  </div>
                                </td>
                                <td>
                                  {name}
                                </td>
                                <td>
                                  {
                                    segmentIds.map((segmentId) => {
                                      const segment = allFreeeSegments.find(_ => _.id === segmentId);
                                      return segment && (
                                        <span key={segmentId} className="badge badge-secondary mr-1">
                                          {segment.name}
                                        </span>
                                      );
                                    })
                                  }
                                </td>
                                <td className="align-middle text-right text-nowrap">
                                  {
                                    canWriteCustomSegments(user, companyId) && (
                                      <div className="d-flex gap-1 justify-content-end">
                                        <ShareButton label="共有" screenType="monthlyBudgets" {...{ company, subjectType: segmentType, subjects: [customSegment], }} />
                                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'カスタムセグメント編集', fields: fields({ freeeSegments: allFreeeSegments, customSegments: customSegments.filter(_ => _ !== customSegment), companyMembers: members, }), }} />
                                        <DeleteButton itemRef={ref} />
                                      </div>
                                    )
                                  }
                                </td>
                              </tr>
                            )}
                          />
                        );
                      })
                    }
                  </tbody>
                )}
              />
            </table>
          ) : (
            <span>No Data</span>
          )
        }
      </div>
    </div>
  );
}

function BatchButton (props) {
  const { collectionName, customSegments, freeeSegments, company, } = props;
  const usedFreeeSegmentIds = customSegments.flatMap(_ => _.segmentIds || []);
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    if(!window.confirm('freeeと同名のカスタム部門を一括登録します。よろしいですか？')) return;

    toggleProcessing(true);
    try {
      await batch(db, freeeSegments.filter(_ => !usedFreeeSegmentIds.includes(_.id)), (batch, freeeSegment) => {
        batch.set(company.ref.collection(collectionName).doc(), {
          name: freeeSegment.name,
          segmentIds: [freeeSegment.id],
          createdAt: new Date(),
        });
      });
      toast.success('一括登録しました');
    } catch(e) {
      toast.error('失敗しました');
      throw e;
    }
    toggleProcessing(false);
  };

  return (
    <Button disabled={isProcessing} onClick={onClick}>
      {isProcessing && <span className="fas fa-spin fa-spinner" />}
      freeeと同名のカスタムセグメントを一括登録する
    </Button>
  );
}
