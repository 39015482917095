import React, { Fragment } from 'react';
import { Button, } from 'reactstrap';
import { keyBy, last, sortBy, omit } from 'lodash';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';

import firebase from '../../firebase';
import { batch } from '../../shared/firebase';
import { mapKeysToJa, mapKeysFromJa, } from '../../shared/texts';
import { parents as sectionParents, } from '../../shared/models/customSection';
import { canWriteCustomSections } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import HelpLink from '../HelpLink';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ShareButton from '../ShareButton';
import CustomSectionFormModal from '../modals/CustomSectionFormModal';
import CompanyPage from '../hocs/CompanyPage';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(CompanyCustomSections);

function CompanyCustomSections(props) {
  const { company, user, members, match: { params: { companyId } } } = props;
  const [isDragging, toggleDragging] = useToggle(false);
  const [showsModal, toggleModal] = useToggle();
  const customSections = useCollectionSubscription(companiesRef.doc(companyId).collection('customSections').orderBy('createdAt'), [companyId]);
  const sortedItems = sortBy(customSections, _ => _.index != null ? _.index : customSections.indexOf(_));
  const freeeSections = [
    { id: 0, name: '未選択' },
    ...useCollectionSubscription(companiesRef.doc(companyId).collection('sections'), [companyId]),
  ];
  const parentFreeeSectionIds = freeeSections.map(_ => _.parent_id).filter(_ => _ != null);
  const tipFreeeSections = freeeSections
    .filter(_ => !parentFreeeSectionIds.includes(_.id))
    .map(_ => ({ ..._, parents: sectionParents(_, freeeSections) }));
  const onSubmit = async (values) => {
    const ref = companiesRef.doc(companyId).collection('customSections').doc();
    try {
      await ref.set({ ...omit(values, 'id'), createdAt: new Date(), index: ((last(sortedItems) || {}).index + 1 || sortedItems.length) });
      toast.success('保存しました');
      toggleModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onDrop = async ({ addedIndex, removedIndex }) => {
    const newIds = arrayMove(sortedItems, removedIndex, addedIndex).map(_ => _.id);
    await batch(db, newIds, (batch, id, index) => {
      batch.update(company.ref.collection('customSections').doc(id), { index });
    });
  };

  return (
    <div className="company-custom-sections">
      <div className="container py-5">
        <div className="d-flex justify-content-end mb-3">
          <HelpLink text="カスタム部門を作成する" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <h3>カスタム部門</h3>
        </div>
        <div className="d-flex justify-content-end gap-1">
          {
            canWriteCustomSections(user, companyId) && (
              <Fragment>
                <BatchButton {...{ company, freeeSections: tipFreeeSections, customSections, }} />
                <Button color="primary" onClick={_ => toggleModal(true)}>
                  <span className="fas fa-plus mr-1" />
                  追加
                </Button>
              </Fragment>
            )
          }
        </div>
        {
          sortedItems.length > 0 ? (
            <table className="table table-hover mt-2">
              <thead>
                <tr>
                  <th></th>
                  <th style={{ minWidth: 150 }}>部門名</th>
                  <th style={{ minWidth: 150 }}>freee部門</th>
                  <th></th>
                </tr>
              </thead>
              <Container
                dragHandleSelector=".drag-handle"
                onDrop={onDrop}
                onDragStart={_ => toggleDragging(true)}
                onDragEnd={_ => toggleDragging(false)}
                dropPlaceholder={{ style: { background: 'eee', } }}
                render={(ref) => (
                  <tbody ref={ref}>
                    {
                      sortedItems.map((customSection) => {
                        const { id, name, sectionIds = [], } = customSection;
                        const itemRef = companiesRef.doc(companyId).collection('customSections').doc(id);
                        return (
                          <Draggable
                            key={id}
                            render={() => (
                              <tr style={{ display: !isDragging && 'table-row' }}>
                                <td style={{ width: 30 }}>
                                  <div className="drag-handle text-muted cursor-pointer">
                                    <span className="fas fa-grip-vertical" />
                                  </div>
                                </td>
                                <td>
                                  {name}
                                </td>
                                <td>
                                  {
                                    sectionIds.map((sectionId) => {
                                      const section = freeeSections.find(_ => _.id === sectionId);
                                      return section && (
                                        <span key={sectionId} className="badge badge-secondary mr-1">
                                          {[...(sectionParents(section, freeeSections).map(_ => _.name)), section.name].join(' - ')}
                                        </span>
                                      );
                                    })
                                  }
                                </td>
                                <td className="align-middle text-right text-nowrap">
                                  {
                                    canWriteCustomSections(user, companyId) && (
                                      <div className="d-flex gap-1 justify-content-end">
                                        <ShareButton label="共有" screenType="monthlyBudgets" {...{ company, subjectType: 'section', subjects: [customSection], }} />
                                        <EditButton itemRef={itemRef} FormModal={CustomSectionFormModal} formProps={{ freeeSections: tipFreeeSections, customSections: customSections.filter(_ => _ !== customSection), companyMembers: members, }} />
                                        <DeleteButton itemRef={itemRef} />
                                      </div>
                                    )
                                  }
                                </td>
                              </tr>
                            )}
                          />
                        );
                      })
                    }
                  </tbody>
                )}
              />
            </table>
          ) : (
            <span>カスタム部門は未登録です</span>
          )
        }
      </div>
      <CustomSectionFormModal isOpen={showsModal} onClickClose={_ => toggleModal(false)} onSubmit={onSubmit} freeeSections={tipFreeeSections} customSections={customSections} companyMembers={members} />
    </div>
  );
}

function BatchButton (props) {
  const { customSections, freeeSections, company, } = props;
  const usedFreeeSectionIds = customSections.flatMap(_ => _.sectionIds || []);
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    if(!window.confirm('freeeと同名のカスタム部門を一括登録します。よろしいですか？')) return;

    toggleProcessing(true);
    try {
      await batch(db, freeeSections.filter(_ => !usedFreeeSectionIds.includes(_.id)), (batch, freeeSection) => {
        batch.set(company.ref.collection('customSections').doc(), {
          name: freeeSection.name,
          sectionIds: [freeeSection.id],
          createdAt: new Date(),
        });
      });
      toast.success('一括登録しました');
    } catch(e) {
      toast.error('失敗しました');
      throw e;
    }
    toggleProcessing(false);
  };

  return (
    <Button disabled={isProcessing} onClick={onClick}>
      {isProcessing && <span className="fas fa-spin fa-spinner" />}
      freeeと同名のカスタム部門を一括登録する
    </Button>
  );
}
