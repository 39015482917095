import React, { Component, Fragment, useEffect, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Input, } from 'reactstrap';
import numeral from 'numeral';
import { orderBy, get, isEqual, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, omit, range, debounce, pick, chunk } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { addYears, format as formatDate, endOfMonth, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useToggle } from 'react-use';

import firebase, { functions } from '../../firebase';
import { canWriteSectionTrial, canWriteSectionBudget, canWriteNote } from '../../abilities';
import { batch, } from '../../shared/firebase';
import { fields as sectionBudgetContainerFields, } from '../../shared/models/sectionBudgetContainer';
import { dimensions, breakdownItemsLimitUnit, } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useQueryParams from '../hooks/useQueryParams';
import ModelFormModal from '../modals/ModelFormModal';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import CommentsDropdown from '../CommentsDropdown';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';
import QuerySelector from '../QuerySelector';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, readFile, fullPathWithParams, trialItemsToRowItems, computeSectionTrialAmount, computeSectionBudget, pickSearch, } from '../../utils';
import env from '../../env';
import './CompanyPeriodComparison.scss';

const fetchFreeePlSectionTrials = functions.httpsCallable('fetchFreeePlSectionTrials', { timeout: 550000 });
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const maxSectionContainersCount = 12;
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率' },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率' },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));

class CompanySectionBudgets extends Component {
  constructor() {
    super();
    this.state = { };
  }
  async componentDidMount() {
    await new Promise(_ => setTimeout(_, 100));
    this.listenComments();
    this.listenNotes();
    this.listenSectionTrials();
  }
  componentWillUnmount() {
    this.unlistenSectionTrials && this.unlistenSectionTrials();
    this.unlistenComments && this.unlistenComments();
    this.unlistenNotes && this.unlistenNotes();
  }
  componentDidUpdate(prevProps, prevState) {
    if(
      ['period', 'targetMonth'].some(_ => prevProps[_] !== this.props[_])
      || ['dimension'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.unlistenSectionTrials && this.unlistenSectionTrials();
      this.listenSectionTrials();
    }
    if(
      ['sectionBudgets', 'comparedSectionBudgets'].some(_ => prevProps[_] !== this.props[_]) ||
      ['comments', 'sectionTrials'].some(_ => prevState[_] !== this.state[_]) ||
      ['customSectionId'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.setState({ isSettingRows: true });
      this.setRowsDebounced();
    }
    if(
      ['allComments'].some(_ => prevState[_] !== this.state[_])
    ) {
      this.setComments();
    }
  }
  listenComments() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenComments = companiesRef
      .doc(companyId)
      .collection('comments')
      .where('queryKey', '==', 'sectionBudgets')
      .orderBy('createdAt')
      .onSnapshot(({ docs }) => {
        this.setState({ allComments: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  listenNotes() {
    const { match: { params: { companyId, } } } = this.props;
    this.unlistenNotes = companiesRef
      .doc(companyId)
      .collection('notes')
      .where('queryKey', '==', 'sectionBudgets')
      .onSnapshot(({ docs }) => {
        const notes = docs.map(_ => ({ id: _.id, ..._.data() }));
        this.setState({ notes, notesByKey: keyBy(notes, 'noteKey') });
      });
  }
  setComments() {
    const { period, company: { fiscalYears = [] } } = this.props;
    const { allComments = [] } = this.state;
    const { start_date: startDate, end_date: endDate } = fiscalYearOfPeriod(period, fiscalYears);
    const comments = allComments.filter(_ => formatDate(startDate, 'YYYYMM') <= _.yearMonth && _.yearMonth <= formatDate(endDate, 'YYYYMM'));
    this.setState({ comments, commentsGroupedByCommenteeKey: groupBy(comments, 'commenteeKey') });
  }
  queryParams(props) {
    const { location: { search } } = props || this.props;
    return qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
  }
  filteredRows = () => {
    const { rows = [], } = this.state;
    let filteredRows = rows;
    return entries(groupBy(filteredRows, 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const showsSubRow = this.state[`shouldShowSubRows__${itemKey}`];
        return [
          ...x,
          mainRow,
          ...subRows.map((row, i) => {
            return {
              ...row,
              breakDownItemIndex: i,
              subItemsCount: subRows.length,
              shows: showsSubRow,
            };
          })
        ];
      }, []);
  }
  async fetch(closingDate) {
    const { company, sections = [], company: { sourceId: companySourceId, }, match: { params: { companyId } } } = this.props;
    const { dimension } = this.queryParams();
    const token = await auth.currentUser.getIdToken(true);
    try {
      await fetchFreeePlSectionTrials({ dimension, companyId, companySourceId, startDate: formatDate(startOfMonthByFiscalYears(closingDate, company), 'YYYY-MM-DD'), endDate: formatDate(closingDate, 'YYYY-MM-DD'), sectionIds: sections.map(_ => _.id) });
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
  }
  months() {
    const { period, targetMonth, company: { fiscalYears } } = this.props;
    const { start_date: startDate, } = fiscalYearOfPeriod(period, fiscalYears);
    return [
      range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _))),
      range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))),
    ];
  }
  monthItems() {
    const { sectionBudgetsGroupedByClosingDate = {}, comparedSectionBudgetsGroupedByClosingDate = {}, } = this.props;
    const { sectionTrialsGroupedByClosingDate = {}, } = this.state;
    const { dimension, } = this.queryParams();
    return this.months().map((months) => {
      return months.map((closingDate) => {
        const balances = sectionTrialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = sectionBudgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          dimension,
        };
      });
    });
  }
  listenSectionTrials = debounce(() => {
    const { match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    this.unlistenSectionTrials = companiesRef
      .doc(companyId)
      .collection('sectionTrials')
      .where('dimension', '==', dimension)
      .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD'))
      .onSnapshot(debounce(({ docs }) => {
        const sectionTrials = docs.map(_ => _.data());
        const sectionTrialsGroupedByClosingDate = groupBy(sectionTrials, _ => formatDate(_.closingDate, 'YYYYMM'));
        const sectionTrialsGroupedByItemKey = groupBy(sectionTrials, 'itemKey');
        this.setState({ sectionTrials, sectionTrialsGroupedByClosingDate, sectionTrialsGroupedByItemKey, });
      }, 300));
  }, 300)
  setRowsDebounced = debounce(() => {
    const rows = this.generateRows();
    this.setState({ rows, isSettingRows: false });
  }, 500)
  generateRows = () => {
    const customSection = this.currentCustomSection();
    if(!customSection) return;

    const { targetMonth, company, accountItems = [], customAccountItems: _customAccountItems = [], sections = [], } = this.props;
    const { dimension, } = this.queryParams();
    const customAccountItems = _customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
    const { notesByKey, } = this.state;
    const sectionsById = keyBy(sections, 'id');
    const relatedSections = customSection.sectionIds.map(_ => sectionsById[_]).filter(_ => _);
    const [prevYearMonthItems, currentYearMonthItems] = this.monthItems();
    const prevYearMonthItemsByMonth = keyBy(prevYearMonthItems, _ => formatDate(_.closingDate, 'MM'));
    const allItems = [...currentYearMonthItems, ...prevYearMonthItems];
    const items = trialItemsToRowItems(allItems, { targetMonth, accountItems, customAccountItems, screenType: 'sectionBudgets', documentType: 'pl', itemType: 'pl', dimension, sectionTrials: true })
    const rows = items
      .map(this.itemToRow.bind(this, { company, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, customSection, sectionsById, relatedSections, }))
      .filter(_ => _.monthColumns.some(_ => ['amount', 'budgetAmount', 'comparedBudgetAmount', 'prevYearAmount'].some(k => abs(_[k]) > 0)));
    return Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
      return [mainItem, ...orderBy(subItems, _ => _.monthColumnsByYearMonth[targetMonth].amount, 'desc')];
    }).flat();
  }
  isOverMonth = (date) => {
    const { targetMonth } = this.props;
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  }
  itemToRow = ({ company, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, customSection, sectionsById, relatedSections, }, item) => {
    const { isCustom = false, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const { sectionBudgetsGroupedItemKey = {}, comparedSectionBudgetsGroupedItemKey = {}, } = this.props;
    const { sectionTrialsGroupedByItemKey = {}, } = this.state;
    const accountCategoryName = accountItemName ? null : item.account_category_name || item.accountCategoryName;
    const { dimension, customSectionId, } = this.queryParams();
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const monthColumns = currentYearMonthItems.map((monthItem, i) => {
      const { closingDate, } = monthItem;
      const isOverMonth = this.isOverMonth(closingDate);
      const prevYearMonthItem = prevYearMonthItemsByMonth[formatDate(closingDate, 'MM')];
      const { closingDate: prevYearClosingDate, } = prevYearMonthItem;
      const yearMonth = formatDate(closingDate, 'YYYYMM');
      const key = `${rowKey}_${yearMonth}`;
      const commenteeKey = [rowKey, customSectionId, yearMonth].join('_');
      const noteKey = [rowKey, customSectionId, yearMonth].join('_');
      const amountBase = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, [closingDate], customAccountItems, customSection.sectionIds, [customSection.id]);
      const amount = isOverMonth ? 0 : amountBase;
      const prevYearAmount = isOverMonth ? 0 : computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, [prevYearClosingDate], customAccountItems, customSection.sectionIds, [customSection.id]);
      const budgetAmount = computeSectionBudget(item, sectionBudgetsGroupedItemKey, dimension, [closingDate], customAccountItems, [customSection.id]);
      const comparedBudgetAmount = computeSectionBudget(item, comparedSectionBudgetsGroupedItemKey, dimension, [closingDate], customAccountItems, [customSection.id]);
      const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
      const budgetDiff = amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = isOverMonth ? budgetAmount : amountBase;
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const note = (notesByKey[noteKey] || {}).value;
      return { key, commenteeKey, noteKey, closingDate, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, sections: relatedSections, };
    });
    const monthColumnsByYearMonth = keyBy(monthColumns, 'yearMonth');
    const totalAmount = sumBy(monthColumns, 'amount');
    const totalBudgetAmount = sumBy(monthColumns, 'budgetAmount');
    const totalComparedBudgetAmount = sumBy(monthColumns, 'comparedBudgetAmount');
    const totalDiffBetweenBudgets = sumBy(monthColumns, 'diffBetweenBudgets');
    const totalEstimatedAmount = sumBy(monthColumns, 'estimatedAmount');
    const totalPrevYearAmount = sumBy(monthColumns, 'prevYearAmount');
    const totalPrevYearDiff = sumBy(monthColumns, 'prevYearDiff');
    const summaryColumns = [
      {
        isSummaryColumn: true,
        name: 'sum',
        key: `${rowKey}_sum`,
        amount: totalAmount,
        budgetAmount: totalBudgetAmount,
        comparedBudgetAmount: totalComparedBudgetAmount,
        diffBetweenBudgets: totalDiffBetweenBudgets,
        budgetDiff: totalAmount - totalBudgetAmount,
        achievementRate: totalAmount / totalBudgetAmount,
        estimatedAmount: totalEstimatedAmount,
        prevYearAmount: totalPrevYearAmount,
        prevYearDiff: totalPrevYearDiff,
        prevYearDiffRate: totalPrevYearDiff / totalPrevYearAmount,
      },
    ];
    return { accountItemName, accountCategoryName, hierarchyLevel, isCategory, isCustom, itemName, itemKey, mainRowItemKey, key: rowKey, subItemName, isSubRow, rowName, monthColumns, monthColumnsByYearMonth, summaryColumns, displayExpression, };
  }
  onSelect = (name, { value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ [name]: value }, location);
    history.replace(encodeURI(path));
  }
  onClickComment = (commenteeKey) => {
    const { [`hovered_commenter__${commenteeKey}`]: commenter } = this;
    if(commenter) {
      commenter.open();
      commenter.focus();
    }
  }
  async sync(closingDate) {
    const { dimension, } = this.queryParams();
    this.setState({ [`isProccessing__${formatDate(closingDate, 'YYYYMM')}__${dimension}`]: true });
    await this.fetch(closingDate);
    this.setState({ [`isProccessing__${formatDate(closingDate, 'YYYYMM')}__${dimension}`]: false });
  }
  toggleSubRows(itemKey) {
    this.setState({ [`shouldShowSubRows__${itemKey}`]: !this.state[`shouldShowSubRows__${itemKey}`] });
    this.setRowsDebounced();
  }
  showMoreSubRows(itemKey) {
    this.setState({ [`breakdownItemsLimitRate_${itemKey}`]: (this.state[`breakdownItemsLimitRate_${itemKey}`] || 1) + 1 });
    this.setRowsDebounced();
  }
  renderRow({ hierarchyLevel = 1, key, isCategory, isCustom, itemName, itemKey, subItemName, isSubRow, rowName, monthColumns, summaryColumns, diff, displayExpression, breakDownItemIndex, subItemsCount, }) {
    const { role, accountItemSettingsById, user, members, period, match: { params: { companyId } } } = this.props;
    const { commentsGroupedByCommenteeKey = {}, notesByKey = {} } = this.state;
    const { dimension, metrics: metricsForFilter = keys(metrics), comparedSectionBudgetContainerId, } = this.queryParams();
    const isComparing = !isEmpty(comparedSectionBudgetContainerId);
    const allColumns = [...monthColumns, ...summaryColumns];
    const shouldShowSubRows = this.state[`shouldShowSubRows__${itemKey}`];
    const accountItemSetting = accountItemSettingsById[`${rowName.replace(/\//g, '_')}__${period}`];
    const breakdownItemsLimitRate = this.state[`breakdownItemsLimitRate_${itemKey}`] || 1;
    const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
    const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
    if(shouldHide) return null;
    const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
    const leftSubItemsCount = subItemsCount - showingCount;
    return (
      <tr key={itemKey + key} className={classnames({ 'sub-row': isSubRow })}>
        <th className="border-right" style={{ width: COLUMN_WIDTH * 2.5, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} colSpan={5 - hierarchyLevel}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              {
                dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                  <Button size="sm" className="small px-0 mr-1" color="link" onClick={this.toggleSubRows.bind(this, itemKey)}>
                    <span className={classnames('fas cursor-pointer', { 'fa-plus': !shouldShowSubRows, 'fa-minus': shouldShowSubRows })} />
                  </Button>
                )
              }
              <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
              {
                isLastSubRow && leftSubItemsCount > 0 && (
                  <div className="mt-2">
                    <Button size="sm" onClick={this.showMoreSubRows.bind(this, itemKey)}>
                      もっと見る (残り{leftSubItemsCount}件)
                    </Button>
                  </div>
                )
              }
            </div>
            <div className="text-nowrap">
              {
                metricsForFilter.filter(_ => !(!isComparing && ['comparedBudgetAmount', 'diffBetweenBudgets'].includes(_))).map((metric) => {
                  const { label } = metrics[metric];
                  return (
                    <div key={metric} style={{ lineHeight: AMOUNT_HEIGHT }}>
                      {label}
                    </div>
                  );
                })
              }
            </div>
          </div>
        </th>
        {
          allColumns.map((column, index) => {
            const { key, } = column;
            return (
              <Column
                key={key}
                _key={key}
                commenterRef={(key, el) => this[`hovered_commenter__${key}`] = el}
                {...{ ...column, role, metricsForFilter, isComparing, period, index, isCategory, itemName, displayExpression, user, members, isSubRow, dimension, subItemName, companyId, commentsGroupedByCommenteeKey, notesByKey, } }
              />
            );
          })
        }
      </tr>
    );
  }
  sortedCustomSections = () => {
    const { writableCustomSections = [], } = this.props;
    return sortBy(writableCustomSections, _ => _.index != null ? _.index : writableCustomSections.indexOf(_));
  }
  currentCustomSection = () => {
    const { writableCustomSections = [] } = this.props;
    const { customSectionId, } = this.queryParams();
    return writableCustomSections.find(_ => _.id === customSectionId);
  }
  onClickExport = async () => {
    const { company, user, period, targetMonth } = this.props;
    const { comparedSectionBudgetContainerId } = this.queryParams();
    const currentCustomSection = this.currentCustomSection();
    const isComparing = !isEmpty(comparedSectionBudgetContainerId);
    this.setState({ isExporting: true });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const filteredRows = this.filteredRows();
    const data = filteredRows.map((row) => {
      return ['budgetAmount', ...(isComparing ? ['comparedBudgetAmount', 'diffBetweenBudgets'] : []), 'amount', 'budgetDiff', 'achievementRate', 'prevYearAmount', 'prevYearDiff', 'prevYearDiffRate', 'note'].map((rowType) => {
        return {
          ...pick(row, ['itemName', 'subItemName']),
          rowType,
          ...(
            row.monthColumns.reduce((x, y) => {
              const { closingDate } = y;
              return {
                ...x,
                [formatDate(closingDate, '_YYYYMM')]: y[rowType],
              };
            }, {})
          ),
          ...(
            row.summaryColumns.reduce((x, y) => {
              const { name } = y;
              return {
                ...x,
                [name]: y[rowType],
              };
            }, {})
          ),
        };
      });
    }).reduce((x, y) => [...x, ...y], []);
    const fileContent = encoder.encode(unparseCsv(data));
    fileDownload(fileContent, `部門別予実月次推移_${targetMonth}.csv`);
    await log(company, 'sectionBudgets', 'exportCsv', user, { period, targetMonth, customSection: currentCustomSection, });
    this.setState({ isExporting: false });
  }
  render() {
    const { company, period, user, role, sectionBudgetContainers = [], members = [], history, location, location: { search }, match: { params: { companyId } } } = this.props;
    const { isImporting = false, isExporting = false, sectionTrials, comments = [], isSettingRows = false, } = this.state;
    const { dimension, } = this.queryParams();
    const { customSectionId, } = this.queryParams();
    const [, currentYearMonthItems] = this.monthItems();
    const pickedSearch = pickSearch(search, ['period', 'customSectionId']);
    const sectionBudgetContainerOptions = sectionBudgetContainers.map(_ => ({ label: _.name, value: _.id }));
    const customSectionOptions = this.sortedCustomSections().map(_ => ({ label: _.name, value: _.id }));
    const dimensionOptions = entries(dimensions).filter(_ => _[0] !== 'sections').map(([k, v]) => ({ label: v, value: k }));
    const filteredRows = this.filteredRows();

    return (
      <div className="company-period-comparison">
        <div className="p-5">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="部門別予実管理を行う" />
          </div>
          <div className="mb-3">
            <h3 className="text-center">部門別予実月次推移</h3>
          </div>
          <div className="py-3">
            <div className="mb-3 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center flex-wrap">
                <QuerySelector paramName="metrics" width={300} isMulti options={metricOptions} label="数値項目" {...{ history, location }} />
                <QuerySelector className="ml-2" paramName="dimension" width={150} options={dimensionOptions} label="明細" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="customSectionId" width={200} options={customSectionOptions} label="カスタム部門" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="sectionBudgetContainerId" width={200} options={sectionBudgetContainerOptions} label="予算" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="comparedSectionBudgetContainerId" width={200} options={sectionBudgetContainerOptions} label="比較予算" {...{ history, location }} />
              </div>
              <div className="d-flex align-items-center">
                {
                  canWriteSectionBudget(user, role) && (
                    <BudgetButton {...{ period, company, currentCustomSection: this.currentCustomSection(), rows: this.generateRows, sectionBudgetContainers, }} />
                  )
                }
                <Button color="secondary" className="ml-2" onClick={this.onClickExport} disabled={isExporting}>
                  <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <CommentsDropdown className="ml-2" companyId={companyId} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} onClickComment={this.onClickComment} />
              </div>
            </div>
            <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
              <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
                <thead className="text-center" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th className="border-right" style={{ width: COLUMN_WIDTH * 2.5 }}>&nbsp;</th>
                    {
                      currentYearMonthItems.map(({ closingDate, exists }, i) => {
                        const isProccessing = this.state[`isProccessing__${formatDate(closingDate, 'YYYYMM')}__${dimension}`];
                        return (
                          <th key={i} className={classnames('text-nowrap', { 'table-success': exists, 'table-danger': sectionTrials && !exists })} style={{ width: COLUMN_WIDTH }}>
                            <Link to={`/companies/${companyId}/monthSectionBudgets${pickedSearch}&targetMonth=${formatDate(closingDate, 'YYYYMM')}`}>
                              {formatDate(closingDate, 'YYYY/MM')}
                            </Link>
                            {
                              canWriteSectionTrial(user, role) && (
                                <Button size="sm" className="small ml-1 px-0" color="link" disabled={isProccessing} onClick={this.sync.bind(this, closingDate)}>
                                  <span className={classnames('fas cursor-pointer', { 'fa-sync': !isProccessing, 'fa-spin fa-spinner': isProccessing})} />
                                </Button>
                              )
                            }
                          </th>
                        );
                      })
                    }
                    <th style={{ width: COLUMN_WIDTH }}>合計</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.filter(_ => !_.isSubRow || _.shows).map((row) => {
                      return this.renderRow(row);
                    })
                  }
                </tbody>
              </Table>
              <OverlayLoading isOpen={isSettingRows} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

class Column extends Component {
  shouldComponentUpdate(nextProps) {
    const watchedPropNames = [
      '_key',
      'commenteeKey',
      'isComparing',
      'metricsForFilter',
      'amount',
      'budgetAmount',
      'comparedBudgetAmount',
      'diffBetweenBudgets',
      'budgetDiff',
      'achievementRate',
      'estimatedAmount',
      'prevYearAmount',
      'prevYearDiff',
      'prevYearDiffRate',
      'commentsGroupedByCommenteeKey',
      'notesByKey',
    ]
    if(!isEqual(pick(this.props, watchedPropNames), pick(nextProps, watchedPropNames))) {
      return true;
    }
    return false;
  }
  render() {
    const { isComparing, role, metricsForFilter, sections = [], isCategory, isCustom, isSubRow, _key: key, commenteeKey, noteKey, companyId, itemName, subItemName, dimension, closingDate, members, user, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, isSummaryColumn, period, commentsGroupedByCommenteeKey, notesByKey, commenterRef, displayExpression } = this.props;
    const pathParams = {
      period,
      targetMonth: formatDate(closingDate, 'YYYYMM'),
      subItems: [
        { dimension: 'sections', itemNames: sections.map(_ => _.name), },
        ...(
          isSubRow ? [{
            dimension,
            itemNames: [subItemName],
          }] : []
        ),
      ],
      singleMonth: '1',
    };
    return (
      <td className={classnames('text-right has-hovered-contents')} style={{ width: COLUMN_WIDTH }}>
        {
          metricsForFilter.includes('budgetAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(budgetAmount) ? numeral(budgetAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          isComparing && metricsForFilter.includes('comparedBudgetAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(comparedBudgetAmount) ? numeral(comparedBudgetAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          isComparing && metricsForFilter.includes('diffBetweenBudgets') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(diffBetweenBudgets) ? numeral(diffBetweenBudgets).format('+' + displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('amount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {
                (isCategory || isCustom || isSummaryColumn) ? (
                  <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
                ) : (
                  <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify(pathParams)}`)}>
                    {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                  </Link>
                )
              }
            </div>
          )
        }
        {
          metricsForFilter.includes('budgetDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(budgetDiff) ? numeral(budgetDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('achievementRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(achievementRate) ? numeral(achievementRate).format('0,0.0%') : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('estimatedAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(estimatedAmount) ? numeral(estimatedAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearAmount') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              <span>{isFiniteNumber(prevYearAmount) ? numeral(prevYearAmount).format(displayExpression) : '-'}</span>
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiff') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiff) ? numeral(prevYearDiff).format('+' + displayExpression) : '-'}
            </div>
          )
        }
        {
          metricsForFilter.includes('prevYearDiffRate') && (
            <div style={{ lineHeight: AMOUNT_HEIGHT }}>
              {isFiniteNumber(prevYearDiffRate) ? numeral(prevYearDiffRate).format('+0,0.0%') : '-'}
            </div>
          )
        }
        {
          !isSummaryColumn && (
            <Fragment>
              <HoveredCommenter
                ref={commenterRef.bind(null, key)}
                companyId={companyId}
                currentUser={user}
                commenteeKey={commenteeKey}
                queryKey="sectionBudgets"
                values={{ yearMonth: formatDate(closingDate, 'YYYYMM') }}
                comments={commentsGroupedByCommenteeKey[commenteeKey]}
                users={members}
                about={['部門別予実月次推移', formatDate(closingDate, 'YYYYMM'), itemName, subItemName].filter(_ => _).join(' ')}
              />
              <HoveredNoter companyId={companyId} noteKey={noteKey} pageType="CompanySectionBudgets" queryKey="sectionBudgets" values={{ yearMonth: formatDate(closingDate, 'YYYYMM') }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
            </Fragment>
          )
        }
      </td>
    );
  }
}

export default CompanyPage(function CompanySectionBudgetsContainer(props) {
  const { location, period, history, match: { params: { companyId } } } = props;
  const { dimension, customSectionId, sectionBudgetContainerId, comparedSectionBudgetContainerId} = useQueryParams();
  const sectionBudgetContainers = useCollectionSubscription(dimension && customSectionId && companiesRef.doc(companyId).collection('sectionBudgetContainers').where('dimension', '==', dimension).where('customSectionId', '==', customSectionId).where('period', '==', period), [dimension, customSectionId, period,]);
  const sortedSectionBudgetContainers = orderBy(sectionBudgetContainers, _ => _.createdAt.toDate(), 'desc');
  const sectionBudgets = useCollectionSubscription(sectionBudgetContainerId && companiesRef.doc(companyId).collection('sectionBudgets').where('sectionBudgetContainerId', '==', sectionBudgetContainerId), [sectionBudgetContainerId]);
  const sectionBudgetsGroupedByClosingDate = groupBy(sectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const sectionBudgetsGroupedItemKey = groupBy(sectionBudgets, 'itemKey');
  const comparedSectionBudgets = useCollectionSubscription(comparedSectionBudgetContainerId && companiesRef.doc(companyId).collection('sectionBudgets').where('sectionBudgetContainerId', '==', comparedSectionBudgetContainerId), [comparedSectionBudgetContainerId]);
  const comparedSectionBudgetsGroupedByClosingDate = groupBy(comparedSectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const comparedSectionBudgetsGroupedItemKey = groupBy(comparedSectionBudgets, 'itemKey');
  useEffect(() => {
    const path = fullPathWithParams({ dimension: dimension || 'none', sectionBudgetContainerId: get(sortedSectionBudgetContainers, [0, 'id']), }, location);
    history.replace(encodeURI(path));
  }, [sectionBudgetContainers]);

  return (
    <CompanySectionBudgets
      {...{
        ...props,
        sectionBudgetContainers: sortedSectionBudgetContainers,
        sectionBudgets,
        sectionBudgetsGroupedByClosingDate,
        sectionBudgetsGroupedItemKey,
        comparedSectionBudgets,
        comparedSectionBudgetsGroupedByClosingDate,
        comparedSectionBudgetsGroupedItemKey,
      }}
    />
  );
});

function BudgetButton (props) {
  const { company, period, currentCustomSection, rows, sectionBudgetContainers, } = props;
  const { customSectionId, dimension, } = useQueryParams();
  const [isExporting, toggleExporting] = useToggle(false);
  const [isImporting, toggleImporting] = useToggle(false);
  const [showsModal, toggleModal] = useToggle(false);
  const onClickExportFormat = async () => {
    if(!currentCustomSection) return;

    toggleExporting(true);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const data = rows().filter(_ => !_.isCustom).map((row) => {
      return {
        ...pick(row, ['accountCategoryName', 'hierarchyLevel', 'accountItemName', 'subItemName']),
        ...(
          row.monthColumns.reduce((x, y) => {
            const { closingDate } = y;
            return {
              ...x,
              [formatDate(closingDate, '_YYYYMM')]: null,
            };
          }, {})
        ),
      };
    });
    const fileContent = encoder.encode(unparseCsv(data));
    fileDownload(fileContent, `予算_${currentCustomSection.name}_${period}_${dimensions[dimension]}.csv`);
    toggleExporting(false);
  }
  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;

    toggleImporting(true);
    await new Promise(_ => setTimeout(_, 300));
    try {
      const sectionBudgetContainerRef = company.ref.collection('sectionBudgetContainers').doc();
      await sectionBudgetContainerRef.set({
        name: formatDate(new Date(), 'YYYY/MM/DD HH:mm:ss'),
        customSectionId,
        period,
        dimension,
        createdAt: new Date(),
      });
      const decoder = new TextDecoder('Shift_JIS');
      const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
      const { data: rows } = parseCsv(fileContent, { header: true });
      const items = rows.map((row) => {
        const { accountItemName, hierarchyLevel, accountCategoryName, subItemName, } = row;
        const itemKey = [accountItemName || 'none', accountCategoryName || 'none'].join('__');
        const key = [itemKey, (subItemName || 'none')].join('__');
        const itemName = accountItemName || accountCategoryName;
        return entries(omit(row, ['accountItemName', 'hierarchyLevel', 'accountCategoryName', 'subItemName'])).map(([yearMonth, amount]) => {
          const [, year, month] = yearMonth.match(/_(\d{4})(\d{2})/);
          const closingDate = formatDate(endOfMonth(new Date(parseInt(year, 10), parseInt(month - 1, 10))), 'YYYY-MM-DD');
          return {
            sectionBudgetContainerId: sectionBudgetContainerRef.id,
            key,
            itemKey,
            itemName,
            accountItemName,
            hierarchyLevel: hierarchyLevel != null ? parseInt(hierarchyLevel, 10) : null,
            accountCategoryName,
            subItemName,
            dimension,
            closingDate,
            customSectionId,
            period,
            amount: parseFloat((amount || '').replace(/,/g, ''), 10) || 0,
          };
        })
      }).flat();
      await chunk(items, 500).reduce(async (x, items) => {
        await x;
        const batch = db.batch();
        items.forEach((item) => {
          const { closingDate, key } = item;
          batch.set(company.ref.collection('sectionBudgets').doc(), item);
        });
        await batch.commit();
      }, Promise.resolve());
      toast.success('インポートしました');
    } catch(e) {
      console.error(e);
      toast.error('インポート失敗しました');
    }
    toggleImporting(false);
    target.value = '';
  };

  return (
    <Fragment>
      <Button onClick={toggleModal.bind(null, true)}>
        予算
      </Button>
      {
        showsModal && (
          <Modal isOpen>
            <ModalHeader>
              予算
            </ModalHeader>
            <ModalBody>
              {
                sectionBudgetContainers.length >= maxSectionContainersCount && (
                  <div className="alert alert-warning">
                    予算数の上限に達しています。
                  </div>
                )
              }
              <div className="d-flex justify-content-end">
                <Button color="secondary" onClick={onClickExportFormat} disabled={isExporting} className="ml-2">
                  <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                  予算フォーマット
                </Button>
                <Button color="secondary" className="ml-2" disabled={isImporting || sectionBudgetContainers.length >= maxSectionContainersCount}> 
                  <label className="m-0 cursor-pointer">
                    <span className={classnames('fas mr-1', { 'fa-upload': !isImporting, 'fa-spin fa-spinner': isImporting})} />
                    予算インポート
                    <Input type="file" className="d-none" onChange={onSelectFiles} accept="text/*" disabled={isImporting || sectionBudgetContainers.length >= maxSectionContainersCount} />
                  </label>
                </Button>
              </div>
              <div className="mt-3">
                <table className="table table-hover">
                  <thead className="thead-light d-none">
                    <tr>
                      <th style={{ minWidth: 200 }}>予算名</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      sectionBudgetContainers.map((sectionBudgetContainer) => {
                        const { id, ref, name, } = sectionBudgetContainer;
                        const deleteItems = async () => {
                          const { docs } = await company.ref.collection('sectionBudgets').where('sectionBudgetContainerId', '==', id).get();
                          await batch(db, docs, (batch, doc) => {
                            batch.delete(doc.ref);
                          });
                          await ref.delete();
                        };

                        return (
                          <tr key={id}>
                            <td>
                              {name}
                            </td>
                            <td className="text-right">
                              <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '予算 編集', fields: sectionBudgetContainerFields, }} />
                              <DeleteButton className="ml-2" deleteItems={deleteItems} />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>閉じる</Button>
            </ModalFooter>
          </Modal>
        )
      }
    </Fragment>
  );
}
