import React, { Component, Fragment, useState, useEffect, useRef, useMemo, } from 'react';
import { FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Input, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { mapValues, omit, pickBy, mergeWith, last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useCounter, useToggle, useDebounce, useMap, useAsync, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { differenceInCalendarMonths, format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { ResizableBox } from 'react-resizable';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, getAllCollectionDataByChunk, } from '../../shared/firebase';
import { dimensionsOfCompany, enabledBudgetSubjectTypes, } from '../../shared/models/company';
import { mapKeysToJa, mapKeysFromJa, jas, } from '../../shared/texts';
import { dimensions, breakdownItemsLimitUnit, documentTypes, budgetSubjectTypes, } from '../../shared/config';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, readFile, fullPathWithParams, trialItemsToRowItems, computeSegmentedTrialAmount, computeBudget, pickSearch, filterRowsByAmount, closingDate, groupSegmentedTrials, groupSegmentedTrialSubItems, } from '../../utils';
import { canWriteNote, } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import useMainTableUtilities from '../hooks/useMainTableUtilities';
import HelpLink from '../HelpLink';
import TrialQueriesSection from '../TrialQueriesSection';
import ProgressButton from '../ProgressButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ModelFormModal from '../modals/ModelFormModal';
import CompanyPage from '../hocs/CompanyPage';
import CommentsDropdown from '../CommentsDropdown';
import HoveredCommenter from '../HoveredCommenter';
import QuerySelector from '../QuerySelector';
import HoveredNoter from '../HoveredNoter';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import Row from './CompanyMonthlyBudgets/Row';
import ShareButton from '../ShareButton';
import TrialScrapingButton from '../TrialScrapingButton';
import JournalsSyncButton from '../JournalsSyncButton';
import AllCollapseButton from '../AllCollapseButton';
import BudgetButton from '../BudgetButton';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const fetchFreeeSegmentedTrials = functions.httpsCallable('fetchFreeeSegmentedTrials', { timeout: 550000 });
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率', isRate: true, },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率', isRate: true, },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const displayColumnTypes = {
  month: { label: '月', },
  quarter: { label: '四半期', },
  half: { label: '半期', },
};
const displayColumnTypeOptions = entries(displayColumnTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default CompanyPage(CompanyMonthlyBudgets);

function CompanyMonthlyBudgets(props) {
  const { accountItemsOrderSetting, accountItems, customAccountItems, company, company: { sourceId: companySourceId }, user, members, period, targetMonth, match: { params: { companyId } } } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    tabKey = 'section__pl',
    dimension = 'none',
    budgetSubjectIds,
    budgetContainerId,
    comparedBudgetContainerId,
    itemNamesForFilter,
    subItemNamesForFilter,
    displayColumnTypes,
  } = queryParams;
  const {
    onClickComment,
    toggleCommenter,
    toggleInitiallyCommentRow,
    subRowsVisibilities,
    setAllSubRowsVisibilities,
    setSubRowsVisibilities,
    setHoveredCommenters,
    onRowsChanged,
  } = useMainTableUtilities();
  const [screenVersion, { inc: updateScreenVersion }] = useCounter();
  const [leftColumnWidth, setLeftColumnWidth] = useState(COLUMN_WIDTH * 2.5);
  const [allRows, setAllRows] = useState([]);
  const isSubFiltered = (queryParams.dimension || 'none') !== 'none' && queryParams.isSubFilter === '1';
  const openItemKeys = useMemo(_ => {
    return ((queryParams.dimension || 'none') === 'none' || isSubFiltered) ? [] : keys(pickBy(subRowsVisibilities, _ => _));
  }, [queryParams.dimension, subRowsVisibilities, isSubFiltered]);
  const [subRowsBreakdownItemsLimitRates, { set: setSubRowsBreakdownItemsLimitRates }] = useMap({});
  const [budgetSubjectType, documentType] = tabKey.split('__');
  const filterForScreen = _ => (_.accountItemCategory?.type === documentType && !_.accountItemCategory?.hidden) || _.isCustom;
  const rows = useMemo(_ => allRows.filter(filterForScreen), [allRows, documentType]);
  const { freeeCollectionName, label: budgetSubjectTypeLabel, idName: subjectIdName, trialsCollectionName, freeeSubjectIdsName, collectionName: subjectCollectionName, nameJa, budgetCollectionName, } = budgetSubjectTypes[budgetSubjectType];
  const dimensionOptions = entries(dimensionsOfCompany(company)).filter(_ => !['sections', ...(budgetSubjectType === 'section' ? [] : ['segment1s', 'segment2s', 'segment3s'])].includes(_[0])).map(([k, v]) => ({ label: v, value: k }));
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const [isSettingRows, toggleSettingRows] = useToggle(false);
  const [isFetchingLastTrials, toggleFetchingLastTrials] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [showsModal, toggleModal] = useToggle();
  const { start_date: startDate, use_industry_template: usesIndustry, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const prevYearMonths = range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _)));
  const currentYearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const budgetSubjects = useCollectionSubscription(company.ref.collection(subjectCollectionName), [budgetSubjectType]);
  const sortedBudgetSubjects = sortBy(budgetSubjects || [], _ => _.index != null ? _.index : (budgetSubjects || []).indexOf(_));
  const budgetContainers = useCollectionSubscription(companiesRef.doc(companyId).collection('budgetContainers').orderBy('index'));
  const freeeSubjects = useCollectionSubscription(companiesRef.doc(companyId).collection(freeeCollectionName), [budgetSubjectType]);
  const freeeSubjectsById = keyBy([{ id: 0, name: '未選択' }, ...freeeSubjects], 'id');
  const { value: budgets, } = useAsync(async () => {
    if(!budgetContainerId || isEmpty(budgetSubjectIds)) return [];

    return (await Promise.all(chunk(budgetSubjectIds, 10).map(async (budgetSubjectIds) => {
      return getCollectionData(companiesRef.doc(companyId).collection('budgets').where('period', '==', period).where('budgetContainerId', '==', budgetContainerId).where('budgetSubjectType', '==', budgetSubjectType).where('budgetSubjectId', 'in', budgetSubjectIds).where('dimension', '==', dimension));
    }))).flat();
  }, [screenVersion, period, budgetContainerId, budgetSubjectIds, dimension]);
  const budgetsGroupedByClosingDate = groupBy(budgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
  const { value: comparedBudgets, } = useAsync(async () => {
    if(!comparedBudgetContainerId || isEmpty(budgetSubjectIds)) return [];

    return (await Promise.all(chunk(budgetSubjectIds, 10).map(async (budgetSubjectIds) => {
      return getCollectionData(companiesRef.doc(companyId).collection('budgets').where('period', '==', period).where('budgetContainerId', '==', comparedBudgetContainerId).where('budgetSubjectType', '==', budgetSubjectType).where('budgetSubjectId', 'in', budgetSubjectIds).where('dimension', '==', dimension));
    }))).flat();
  }, [screenVersion, period, comparedBudgetContainerId, budgetSubjectIds, dimension]);
  const comparedBudgetsGroupedItemKey = groupBy(comparedBudgets, 'itemKey');
  const trialsRef = (documentType) => {
    return company.ref
      .collection(trialsCollectionName + suffix(documentType))
      .where('dimension', '==', 'none')
      .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'));
  };
  const plTrials = useCollectionSubscription(trialsRef('pl'), [tabKey, dimension, period]);
  const crTrials = useCollectionSubscription(trialsRef('cr'), [tabKey, dimension, period]);
  const trials = useMemo(_ => [...plTrials, ...crTrials], [plTrials, crTrials]);
  const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubjectRowsGroupedByItemKey = groupSegmentedTrials(trials.flatMap(t => (t[freeeCollectionName] || []).map(_ => ({ ...t, ...omit(_, 'id'), subjectId: _.id, }))));

  const subItemCollectionName = trialsCollectionName + 'SubItemChunks__' + dimension;
  const refs = useMemo(_ =>
    isSubFiltered ? (
      [company.ref.collection(subItemCollectionName)]
    ) : chunk(openItemKeys, 10).map(_ => company.ref.collection(subItemCollectionName).where('itemKey', 'in', _))
  , [isSubFiltered, subItemCollectionName, openItemKeys]);
  const trialSubItemChunks = useCollectionsFetch(refs.map((ref) => {
    return ref
      .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'));
  }), [refs, period]);
  const trialSubItems = useMemo(_ => trialSubItemChunks.flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ }))), [trialSubItemChunks]);
  const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubItemsGroupedByItemKey = groupSegmentedTrialSubItems(trialSubItems);

  const comments = useCollectionSubscription(companiesRef.doc(companyId).collection('comments').where('queryKey', '==', budgetCollectionName).orderBy('createdAt'));
  const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
  const notes = useCollectionSubscription(companiesRef.doc(companyId).collection('notes').where('queryKey', '==', budgetCollectionName));
  const notesByKey = keyBy(notes, 'noteKey');
  const toggleTab = (tabKey) => {
    const [targetBudgetSubjectType] = tabKey.split('__');
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, tabKey, ...(budgetSubjectType !== targetBudgetSubjectType && { budgetContainerId: null, budgetSubjectIds: null, }), }));
  };
  const relatedCustomAccountItems = customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
  const budgetSubjectOptions = sortedBudgetSubjects.map(_ => ({ label: _.name, value: _.id }));
  const budgetContainerOptions = budgetContainers.map(_ => ({ label: _.name, value: _.id }));
  const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
  const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));
  const generateMonthsItems = (options) => {
    const { budgetsGroupedByClosingDate } = options;
    return [prevYearMonths, currentYearMonths].map((months) => {
      return months.map((closingDate) => {
        const balances = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = budgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const trialSubItems = (options?.trialSubItemsGroupedByClosingDate || trialSubItemsGroupedByClosingDate)[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
          dimension,
        };
      });
    });
  };
  const monthsItems = generateMonthsItems({ budgetsGroupedByClosingDate });
  const prevYearMonthItemsByMonth = keyBy(monthsItems[0], _ => formatDate(_.closingDate, 'MM'));
  const budgetSubjectsById = keyBy(budgetSubjects, 'id');
  const selectedBudgetSubjects = (budgetSubjectIds || []).map(_ => budgetSubjectsById[_]);
  const generateRows = (options) => {
    const { monthsItems, budgetSubjects, prevYearMonthItemsByMonth, budgetsGroupedByItemKey, } = options;
    const trialSubItemsGroupedByItemKey = options?.trialSubItems && groupSegmentedTrialSubItems(options.trialSubItems);
    const items = trialItemsToRowItems(monthsItems.flat(), { targetMonth, accountItems, customAccountItems: relatedCustomAccountItems, screenType: 'sectionBudgets', documentType, itemType: documentType, dimension, budgetSubjectsFreeeName: freeeCollectionName, sortsAllPlAndCrCategories: true, accountItemsOrderSetting, })
    const mainItems = items.filter(_ => !_.isCategory && !_.isSubRow && !_.isCustom);
    const rows = items
      .map(itemToRow.bind(null, mainItems, { monthsItems, budgetSubjects, prevYearMonthItemsByMonth, budgetsGroupedByItemKey, trialSubItemsGroupedByItemKey, }))
      .filter(_ => _.columns.some(_ => abs(_.amount) > 0 || abs(_.budgetAmount) > 0 || abs(_.prevYearAmount) > 0));
    return entries(groupBy(
      Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
        return [mainItem, ...orderBy(subItems, [_ => Math.abs(_.monthColumnsByYearMonth[targetMonth].amount), _ => Math.abs(_.monthColumnsByYearMonth[targetMonth].budgetAmount)], ['desc', 'desc'])];
      }).flat()
    , 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const _subRows = subRows.map((row, i) => {
          return {
            ...row,
            breakDownItemIndex: i,
            subItemsCount: subRows.length,
          };
        });
        return [
          ...x,
          { ...mainRow, subRows: _subRows },
          ..._subRows
        ];
      }, []);
  };
  const computeOverMonth = (date) => {
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  };
  const quarters = chunk(currentYearMonths, 3);
  const halfs = chunk(currentYearMonths, 6);
  const itemToRow = (mainItems, options, item) => {
    const { monthsItems, budgetSubjects, prevYearMonthItemsByMonth, budgetsGroupedByItemKey, } = options;
    const _trialSubItemsGroupedByItemKey = options.trialSubItemsGroupedByItemKey || trialSubItemsGroupedByItemKey;
    const { isCustom = false, accountItemCategory, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const accountCategoryName = accountItemName ? null : item.account_category_name || item.accountCategoryName;
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const monthColumns = monthsItems[1].map((monthItem, i) => {
      const { closingDate, } = monthItem;
      const isOverMonth = computeOverMonth(closingDate);
      const prevYearMonthItem = prevYearMonthItemsByMonth[formatDate(closingDate, 'MM')];
      const { closingDate: prevYearClosingDate, } = prevYearMonthItem;
      const yearMonth = formatDate(closingDate, 'YYYYMM');
      const key = `${rowKey}_${yearMonth}`;
      const budgetSubjectRows = budgetSubjects.map((budgetSubject) => {
        const key = `${rowKey}_${yearMonth}_${budgetSubject?.id}`;
        const freeeSubjects = budgetSubject?.[freeeSubjectIdsName].map(_ => freeeSubjectsById[_]).filter(_ => _);
        const commenteeKey = [rowKey, budgetSubject?.id, yearMonth].join('_');
        const noteKey = [rowKey, budgetSubject?.id, yearMonth].join('_');
        const amountBase = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, [closingDate], customAccountItems, budgetSubject?.[freeeSubjectIdsName], [budgetSubject?.id]);
        const amount = isOverMonth ? 0 : amountBase;
        const prevYearAmount = isOverMonth ? 0 : computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, [prevYearClosingDate], customAccountItems, budgetSubject?.[freeeSubjectIdsName], [budgetSubject?.id]);
        const budgetAmount = computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, [closingDate], customAccountItems, [budgetSubject?.id], mainItems);
        const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, [closingDate], customAccountItems, [budgetSubject?.id], mainItems);
        const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
        const budgetDiff = isOverMonth ? null : amount - budgetAmount;
        const achievementRate = amount / budgetAmount;
        const estimatedAmount = isOverMonth ? budgetAmount : amountBase;
        const prevYearDiff = amount - prevYearAmount;
        const prevYearDiffRate = prevYearDiff / prevYearAmount;
        const note = (notesByKey[noteKey] || {}).value;
        return { budgetSubject, key, commenteeKeys: [commenteeKey], noteKeys: [noteKey], closingDate, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, freeeSubjects, amountBase, };
      });
      const budgetSubjectsSummaryRow = {
        key: `${rowKey}_${yearMonth}_summary`,
        isSummaryColumn: true,
        label: '合計',
        closingDate,
        yearMonth,
        amount: sumBy(budgetSubjectRows, 'amount'),
        budgetAmount: sumBy(budgetSubjectRows, 'budgetAmount'),
        comparedBudgetAmount: sumBy(budgetSubjectRows, 'comparedBudgetAmount'),
        diffBetweenBudgets: sumBy(budgetSubjectRows, 'diffBetweenBudgets'),
        budgetDiff: sumBy(budgetSubjectRows, 'budgetDiff'),
        achievementRate: sumBy(budgetSubjectRows, 'amount') / sumBy(budgetSubjectRows, 'budgetAmount'),
        estimatedAmount: sumBy(budgetSubjectRows, 'estimatedAmount'),
        prevYearAmount: sumBy(budgetSubjectRows, 'prevYearAmount'),
        prevYearDiff: sumBy(budgetSubjectRows, 'prevYearDiff'),
        prevYearDiffRate: sumBy(budgetSubjectRows, 'prevYearDiff') / sumBy(budgetSubjectRows, 'prevYearAmount'),
      };
      const freeeSubjects = budgetSubjectRows.flatMap(_ => _.freeeSubjects);
      const commenteeKeys = budgetSubjectRows.map(_ => _.commenteeKeys[0]);
      const noteKeys = budgetSubjectRows.map(_ => _.noteKeys[0]);
      const [amountBase, amount, prevYearAmount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, estimatedAmount, prevYearDiff] = ['amountBase', 'amount', 'prevYearAmount', 'budgetAmount', 'comparedBudgetAmount', 'diffBetweenBudgets', 'budgetDiff', 'estimatedAmount', 'prevYearDiff'].map(_ => sumBy(budgetSubjectRows, _));
      const achievementRate = amount / budgetAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const notes = noteKeys.map(_ => (notesByKey[_] || {}).value);
      return { key, columnType: 'month', csvLabel: '_' + yearMonth, commenteeKeys, noteKeys, closingDate, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, notes, note: notes.join('\n'), freeeSubjects, amountBase, budgetSubjectRows, budgetSubjectsSummaryRow, };
    });
    const monthColumnsByYearMonth = keyBy(monthColumns, 'yearMonth');
    const prevYearMonthsByMm = keyBy(prevYearMonths, _ => formatDate(_, 'MM'));
    const computeSummaryColumn = (columnType, termMonths, termIndex) => {
      const notOverTermMonths = termMonths.filter(_ => !computeOverMonth(_));
      const notOverPrevYearTermMonths = notOverTermMonths.map(_ => prevYearMonthsByMm[formatDate(_, 'MM')]);
      const termMonthColumns = termMonths.map(_ => monthColumnsByYearMonth[formatDate(_, 'YYYYMM')]);
      const key = `${rowKey}_${termMonths.map(_ => formatDate(_, 'YYYYMM')).join(',')}`;
      const amount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, notOverTermMonths, customAccountItems, budgetSubjects.flatMap(_ => _?.[freeeSubjectIdsName]), budgetSubjects.map(_ => _.id));
      const prevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, _trialSubItemsGroupedByItemKey, dimension, notOverPrevYearTermMonths, customAccountItems, budgetSubjects.flatMap(_ => _?.[freeeSubjectIdsName]), budgetSubjects.map(_ => _.id));
      const budgetAmount = computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, termMonths, customAccountItems, budgetSubjects.map(_ => _.id), mainItems);
      const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, termMonths, customAccountItems, budgetSubjects.map(_ => _.id), mainItems);
      const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
      const budgetDiff = isCustom ? (termMonths.some(computeOverMonth) ? null : amount - budgetAmount) : amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = isCustom ? (termMonths.some(computeOverMonth) ? null : amount) : sumBy(termMonthColumns, 'estimatedAmount');
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      return { key, isSummaryColumn: true, csvLabel: ({ quarter: `${termIndex+1}Q`, half: `${({ 0: '上', 1: '下' })[termIndex]}半期` })[columnType], columnType, closingDate, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, };
    };
    const quarterColumns = quarters.map(computeSummaryColumn.bind(null, 'quarter'));
    const halfColumns = halfs.map(computeSummaryColumn.bind(null, 'half'));
    const columns = chunk(chunk(monthColumns, 3), 2).flatMap((halfQuarters, halfIndex) => [...halfQuarters.flatMap((monthColumns, quarterIndex) => [...monthColumns, quarterColumns[halfIndex * 2 + quarterIndex]]), halfColumns[halfIndex]]);
    const totalAmount = sumBy(monthColumns, 'amount');
    const totalBudgetAmount = sumBy(monthColumns, 'budgetAmount');
    const totalBudgetDiff = sumBy(monthColumns, 'budgetDiff');
    const totalComparedBudgetAmount = sumBy(monthColumns, 'comparedBudgetAmount');
    const totalDiffBetweenBudgets = sumBy(monthColumns, 'diffBetweenBudgets');
    const totalEstimatedAmount = sumBy(monthColumns, 'estimatedAmount');
    const totalPrevYearAmount = sumBy(monthColumns, 'prevYearAmount');
    const totalPrevYearDiff = sumBy(monthColumns, 'prevYearDiff');
    const summaryColumns = [
      {
        isSummaryColumn: true,
        name: 'sum',
        key: `${rowKey}_sum`,
        amount: totalAmount,
        budgetAmount: totalBudgetAmount,
        comparedBudgetAmount: totalComparedBudgetAmount,
        diffBetweenBudgets: totalDiffBetweenBudgets,
        budgetDiff: totalBudgetDiff,
        achievementRate: totalAmount / totalBudgetAmount,
        estimatedAmount: totalEstimatedAmount,
        prevYearAmount: totalPrevYearAmount,
        prevYearDiff: totalPrevYearDiff,
        prevYearDiffRate: totalPrevYearDiff / totalPrevYearAmount,
      },
    ];
    const comments = monthColumns.flatMap(_ => _.commenteeKeys.flatMap(_ => commentsGroupedByCommenteeKey[_] || []));
    return {
      ...item,
      budgetSubjects,
      accountItemCategory,
      accountItemName,
      accountCategoryName,
      hierarchyLevel,
      isCategory,
      isCustom,
      itemName,
      itemKey,
      mainRowItemKey,
      key: rowKey,
      subItemName,
      isSubRow,
      rowName,
      displayExpression,
      monthColumns,
      monthColumnsByYearMonth,
      columns,
      summaryColumns,
      comments,
    };
  }
  const rowsDependencies = [budgetSubjectIds, budgetContainerId, comparedBudgetContainerId, trials, trialSubItems, budgets, targetMonth, comparedBudgets, documentType];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setAllRows(generateRows({ monthsItems, budgetSubjects: selectedBudgetSubjects, prevYearMonthItemsByMonth, budgetsGroupedByItemKey, }));
    toggleSettingRows(false);
  }, 1000, rowsDependencies);

  const filterRows = (rows) => {
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(!isSubFiltered) {
      entries(metrics).map(([metric, { isRate }]) => {
        filteredRows = filterRowsByAmount(filteredRows, queryParams, metric, _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
      });
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          isSubFiltered && entries(metrics).map(([metric, { isRate }]) => {
            filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, metric, _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
          });
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                columns: mainRow.columns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.columns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.columns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.columns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.columns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
                summaryColumns: mainRow.summaryColumns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.columns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.columns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.columns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.columns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  };
  const filteredRows = filterRows(rows);

  const onClickExport = async (isAll) => {
    toggleExporting(true);
    const { metrics: metricsForFilter = keys(metrics), } = queryParams;
    const isComparing = !isEmpty(comparedBudgetContainerId);
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const trialSubItems = dimension !== 'none' && (await getAllCollectionDataByChunk(company.ref
      .collection(subItemCollectionName)
      .where('closingDate', '>=', formatDate(prevYearMonths[0], 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(last(currentYearMonths), 'YYYY-MM-DD'))
    )).flatMap(_ => _.subItems.map(__ => ({ ..._, ...__ })));
    const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
    const allRows = isAll ? await (async () => {
      const budgets = await getCollectionData(companiesRef.doc(companyId).collection('budgets').where('period', '==', period).where('budgetContainerId', '==', budgetContainerId).where('budgetSubjectType', '==', budgetSubjectType).where('dimension', '==', dimension));
      const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
      const budgetsGroupedByClosingDate = groupBy(budgets, _ => formatDate(_.closingDate, 'YYYYMM'));
      return sortedBudgetSubjects.map((budgetSubject) => {
        const monthsItems = generateMonthsItems({ budgetsGroupedByClosingDate, trialSubItemsGroupedByClosingDate, });
        const prevYearMonthItemsByMonth = keyBy(monthsItems[0], _ => formatDate(_.closingDate, 'MM'));
        return generateRows({ monthsItems, budgetSubjects: [budgetSubject], prevYearMonthItemsByMonth, budgetsGroupedByItemKey, trialSubItems, }).map(_ => ({ ..._, budgetSubject }));
      }).flat();
    })() : generateRows({ monthsItems, budgetSubjects: selectedBudgetSubjects, prevYearMonthItemsByMonth, budgetsGroupedByItemKey, trialSubItems, });
    const data = filterRows(allRows.filter(filterForScreen)).map((row) => {
      return [...metricsForFilter, ...(row.columns.every(_ => isEmpty(_.notes)) ? [] : ['note'])].map((rowType) => {
        return {
          budgetSubjectName: row.budgetSubjects?.map(_ => _.name).join(','),
          ...pick(row, ['itemName', 'subItemName']),
          rowType: jas[rowType],
          ...(
            row.columns.filter(_ => isEmpty(displayColumnTypes) || displayColumnTypes.includes(_.columnType)).reduce((x, y) => {
              return {
                ...x,
                [y.csvLabel]: y[rowType],
              };
            }, {})
          ),
          ...(
            row.summaryColumns.reduce((x, y) => {
              const { name } = y;
              return {
                ...x,
                [name]: y[rowType],
              };
            }, {})
          ),
        };
      });
    }).reduce((x, y) => [...x, ...y], []);
    const fileContent = encoder.encode(unparseCsv(data.map(_ => mapKeysToJa(_, { budgetSubjectName: nameJa, }))));
    fileDownload(fileContent, `予実月次推移_${targetMonth}.csv`);
    await log(company, 'monthlyBudgets', 'exportCsv', user, { period, targetMonth, budgetSubjects: (isAll ? [{ name: '全部門', }] : selectedBudgetSubjects), });
    toggleExporting(false);
  };
  // NOTE: タブが切り替わったあと、budgetSubjectsが一旦空になるまで1周待つ必要がある。非同期にすれば解決するので、useDebounceを用いる。
  useDebounce(() => {
    const path = fullPathWithParams({
      dimension: dimension || 'none',
      budgetSubjectIds: budgetSubjectIds || (queryParams.budgetSubjectId && [queryParams.budgetSubjectId]) || [get(sortedBudgetSubjects, [0, 'id'])],
      budgetContainerId: budgetContainers.some(_ => _.id === budgetContainerId) ? budgetContainerId : get(budgetContainers, [0, 'id']),
    }, location);
    history.replace(encodeURI(path));
  }, 1000, [tabKey, budgetContainers, budgetSubjects]);
  useEffect(() => {
    toggleInitiallyCommentRow(rows);
    onRowsChanged();
  }, [rows]);

  return (
    <div className="company-monthly-budgets">
      <div className="px-3 py-3">
        <div className="d-flex justify-content-end mb-2">
          <HelpLink text="予実管理を行う" />
        </div>
        <div className="mb-2">
          <h3 className="text-center">予実月次推移</h3>
        </div>
        <Nav tabs>
          {
            entries(enabledBudgetSubjectTypes(company)).flatMap(([budgetSubjectType, { label: budgetSubjectTypeLabel }]) => {
              return entries(pick(documentTypes, ['pl', 'cr'])).map(([documentType, { label: documentTypeLabel }]) => {
                const key = [budgetSubjectType, documentType].join('__');
                const label = [budgetSubjectTypeLabel, documentTypeLabel].join(' ');
                return (
                  <NavItem key={key}>
                    <NavLink className={classnames('cursor-pointer', { active: tabKey === key })} onClick={toggleTab.bind(null, key)}>
                      {label}
                    </NavLink>
                  </NavItem>
                );
              });
            })
          }
        </Nav>
        <div className="py-3">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <TrialQueriesSection 
              company={company}
              screenType="monthlyBudgets"
              uncontrols={['budgetSubjectIds', 'budgetContainerId', 'comparedBudgetContainerId']}
              items={[
                [
                  {
                    name: 'budgetSubjectIds',
                    type: 'multiSelect',
                    options: budgetSubjectOptions,
                    label: budgetSubjectTypeLabel,
                    isFront: true,
                    props: { width: 200, selectorProps: { isClearable: false } },
                  },
                  {
                    name: 'metrics',
                    type: 'multiSelect',
                    options: metricOptions,
                    label: '数値項目',
                    props: { width: 200, }
                  },
                  {
                    name: 'itemNamesForFilter',
                    type: 'multiSelect',
                    options: itemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                  {
                    name: 'dimension',
                    type: 'select',
                    options: dimensionOptions,
                    label: '明細',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
                [
                  ...(
                    dimension !== 'none' ? [
                      {
                        name: 'isSubFilter',
                        type: 'boolean',
                        label: '明細単位で検索する',
                      },
                    ] : []
                  ),
                  ...(
                    dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                      {
                        name: 'subItemNamesForFilter',
                        type: 'multiSelect',
                        options: subItemNameOptions,
                        label: '明細名',
                        props: { width: 300, }
                      },
                    ] : []
                  ),
                ],
                [
                  {
                    name: 'budgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'comparedBudgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '比較予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'displayColumnTypes',
                    type: 'multiSelect',
                    options: displayColumnTypeOptions,
                    label: '表示列',
                    isFront: true,
                    props: { width: 250, },
                  },
                ],
                ...entries(metrics).map(([metric, { label, isRate }]) => {
                  return [
                    {
                      name: metric + 'Min',
                      type: 'numeric',
                      label: label + '下限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                    {
                      name: metric + 'Max',
                      type: 'numeric',
                      label: label + '上限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                  ];
                })
              ]}
            />
            <div className="d-flex align-items-center gap-1 text-nowrap">
              <BudgetButton {...{ period, company, accountItems, }} onChangeBudgets={updateScreenVersion} />
              <Button color="secondary" onClick={onClickExport.bind(null, true)} disabled={isExporting}>
                全部門
                <span className={classnames('ml-1 fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
              </Button>
              <Button color="secondary" onClick={onClickExport.bind(null, false)} disabled={isExporting}>
                <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
              </Button>
              <ShareButton screenType="monthlyBudgets" {...{ company, subjectType: budgetSubjectType, subjects: selectedBudgetSubjects, }} />
              <CommentsDropdown company={company} companyId={companyId} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} onClickComment={onClickComment(rows)} />
            </div>
          </div>
          <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
            <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
              <thead className="text-center" style={{ lineHeight: '20px' }}>
                <tr>
                  <th className="text-left border-right p-0">
                    <ResizableBox className="p-2 d-flex align-items-center" width={COLUMN_WIDTH * 2.5} height={44} axis="x" resizeHandles={['e']} onResize={(e, _) => setLeftColumnWidth(_.size.width)}>
                      {
                        dimension !== 'none' && (
                          <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={subRowsVisibilities} setVisibilities={setAllSubRowsVisibilities} />
                        )
                      }
                      &nbsp;
                    </ResizableBox>
                  </th>
                  {
                    chunk(chunk(monthsItems[1], 3), 2).map((halfQuarters, halfIndex) => {
                      return (
                        <Fragment key={halfIndex}>
                          {
                            halfQuarters.map((monthItems, quarterIndex) => {
                              return (
                                <Fragment key={quarterIndex}>
                                  {
                                    (isEmpty(displayColumnTypes) || displayColumnTypes.includes('month')) && monthItems.map(({ closingDate, exists }, i) => {
                                      return (
                                        <th key={i} className={classnames('text-nowrap', { 'table-success': exists, 'table-danger': !exists })} style={{ width: COLUMN_WIDTH }}>
                                          <Link to={`/companies/${companyId}/monthBudgets?${qs.stringify({ period, budgetSubjectIds, targetMonth: formatDate(closingDate, 'YYYYMM'), tabKey: [budgetSubjectType, documentType].join('__'), })}`}>
                                            {formatDate(closingDate, 'YYYY/MM')}
                                          </Link>
                                          <JournalsSyncButton size="sm" className="small ml-1 px-0" color="link" company={company} period={period} targetMonth={formatDate(closingDate, 'YYYYMM')} />
                                        </th>
                                      );
                                    })
                                  }
                                  {
                                    (isEmpty(displayColumnTypes) || displayColumnTypes.includes('quarter')) && (
                                      <th className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                                        {halfIndex * 2 + quarterIndex + 1}Q
                                      </th>
                                    )
                                  }
                                </Fragment>
                              );
                            })
                          }
                          {
                            (isEmpty(displayColumnTypes) || displayColumnTypes.includes('half')) && (
                              <th className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                                {({ 0: '上', 1: '下' })[halfIndex]}半期
                              </th>
                            )
                          }
                        </Fragment>
                      );
                    })
                  }
                  <th style={{ width: COLUMN_WIDTH }}>合計</th>
                </tr>
              </thead>
              <tbody>
                {
                  filteredRows.map((row) => {
                    const { isSubRow, itemKey, } = row;
                    const showsSubRows = dimension !== 'none' && !!subRowsVisibilities[itemKey];
                    if(isSubRow && !showsSubRows) return null;

                    const breakdownItemsLimitRate = subRowsBreakdownItemsLimitRates[itemKey] || 1;
                    const toggleSubRows = setSubRowsVisibilities.bind(null, itemKey, !showsSubRows);
                    const showMoreSubRows = setSubRowsBreakdownItemsLimitRates.bind(null, itemKey, breakdownItemsLimitRate + 1);
                    return <Row pageProps={props} row={row} {...{ company, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, leftColumnWidth, }} />
                  })
                }
              </tbody>
            </Table>
            <OverlayLoading isOpen={isSettingRows} />
          </div>
        </div>
      </div>
    </div>
  );
}
