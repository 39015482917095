import React, { useState } from 'react';
import { sortBy, omit, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, Input } from 'reactstrap';
import { useToggle, } from 'react-use';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';
import uuid from 'uuid';
import { format as formatDate, addDays } from 'date-fns';
import DatePicker from 'react-datepicker';

import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import ModalButton from './ModalButton';

export default function ShareButton ({ label, screenType, company, subjectType, subjects, ...extraProps }) {
  const [selectedDate, selectDate] = useState(addDays(new Date(), 14));
  const sortedSubjectIds = sortBy(subjects.map(_ => _?.id), _ => _);
  const budgetPublicKey = useDocumentSubscription(sortedSubjectIds.join('').length > 0 && company.ref.collection('budgetPublicKeys').doc(sortedSubjectIds.join('__')), [sortedSubjectIds.join('__')], { strict: true });
  const url = `${window.location.origin}/p/${screenType}?subjectType=${subjectType}&publicKey=${budgetPublicKey?.publicKey}`;
  const content = () => {
    const onClickCopy = () => {
      copy(url);
      toast.success('コピーしました');
    };
    const onClickShare = async () => {
      if(!window.confirm('本当に共有URLを作成しますか？')) return;

      await company.ref.collection('budgetPublicKeys').doc(sortedSubjectIds.join('__')).set({
        subjectType,
        publicKey: uuid(),
        publicExpiredOn: selectedDate,
        subjectIds: sortedSubjectIds,
      });
    };
    const onClickUnshare = async () => {
      if(!window.confirm('共有URLを削除しますか？')) return;

      await company.ref.collection('budgetPublicKeys').doc(sortedSubjectIds.join('__')).delete();
    };

    return budgetPublicKey != null ? (
      <div>
        <div className="d-flex gap-1">
          <Input className="flex-grow-1" readOnly defaultValue={url} />
          <Button color="primary" onClick={onClickCopy}>
            <span className="fas fa-copy" />
          </Button>
          <Button color="danger" onClick={onClickUnshare}>
            <span className="fas fa-times" />
          </Button>
        </div>
        <div className="d-flex text-muted small">
          {budgetPublicKey.publicExpiredOn != null && `共有期限: ${formatDate(budgetPublicKey.publicExpiredOn.toDate(), 'YYYY/MM/DD')}`}
        </div>
      </div>
    ) : (
      <div className="d-flex flex-column align-items-center gap-2">
        <div>
          <div className="small text-muted">共有期限</div>
          <DatePicker
            dateFormat="yyyy/MM/dd"
            selected={selectedDate}
            onChange={selectDate}
          />
        </div>
        <Button color="primary" onClick={onClickShare} disabled={selectedDate == null}>
          共有URLを作成する
        </Button>
      </div>
    );
  };

  return !company.disablesBudgetsShare && (
    <ModalButton title="共有" {...extraProps} content={content}>
      <span className="fas fa-share-alt" />
      {label && <span className="ml-1">{label}</span>}
    </ModalButton>
  );
};
