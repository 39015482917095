import React, { Component } from 'react';
import { Form, FormGroup, Button, Input, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { isEmpty, } from 'lodash';

import firebase, { functions } from '../../firebase';
import { autoLink, nl2br } from '../../utils';
import './index.css';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const setNote = functions.httpsCallable('setNote');
const deleteNote = functions.httpsCallable('deleteNote');

export default class HoveredNoter extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevState.shouldShowDropdown && this.state.shouldShowDropdown) {
      this.setFormDisplay();
    }
    if(!prevState.shouldShowForm && this.state.shouldShowForm) {
      this.setValues();
    }
  }
  setValues() {
    const { note: { value } = {} } = this.props;
    this.setState({ formValue: value });
  }
  setFormDisplay() {
    const { note: { value } = {} } = this.props;
    this.setState({ shouldShowForm: !value });
  }
  toggleDropdown = (key) => {
    if(this.props.unopenable) return;

    this.setState({ shouldShowDropdown: !this.state.shouldShowDropdown });
  }
  onChangeText = (name, { target: { value } }) => {
    this.setState({ [name]: value });
  }
  onSubmit = async (event) => {
    event.preventDefault();
    const { companyId, noteKey, queryKey, pageType, values, note: { id } = {}, isPublic = false, onSubmitted = _ => _, } = this.props;
    const { formValue } = this.state;
    if(!formValue) return;

    this.setState({ isSubmitting: true });
    try {
      if(isPublic) {
        await setNote({ companyId, noteId: id, values: { ...values, noteKey, queryKey, pageType, value: formValue }, });
      } else {
        await companiesRef.doc(companyId).collection('notes').doc(...(id ? [id] : [])).set({ ...values, noteKey, queryKey, pageType, value: formValue });
      }
      toast.success('保存しました');
      this.closeForm();
      this.clear();
    } catch(e) {
      console.error(e);
      toast.error('保存失敗しました');
    }
    this.setState({ isSubmitting: false });
    onSubmitted();
  }
  open = () => this.setState({ shouldShowDropdown: true })
  close = () => this.setState({ shouldShowDropdown: false })
  openForm = () => this.setState({ shouldShowForm: true })
  closeForm = () => this.setState({ shouldShowForm: false })
  clear = () => this.setState({ formValue: null })
  cancel = () => {
    this.closeForm();
    this.clear();
  }
  onClickDelete = async () => {
    if(!window.confirm('本当に削除しますか？')) return;
    const { companyId, note: { id }, isPublic = false, onDeleted = _ => _, } = this.props;
    if(isPublic) {
      await deleteNote({ companyId, noteId: id, });
    } else {
      await companiesRef.doc(companyId).collection('notes').doc(id).delete();
    }
    toast.success('削除しました');
    this.close();
    onDeleted();
  }
  render() {
    const { writable, note: { value = '' } = {}, isPublic = false, unopenable = false, onClick = _ => _, } = this.props;
    const { shouldShowDropdown = false, shouldShowForm = false, formValue = '', isSubmitting = false, } = this.state;
    return (isPublic || writable || !isEmpty(value)) && (
      <div className={classnames('hovered-noter', { 'always-visible': shouldShowDropdown || value })} style={{ zIndex: shouldShowDropdown && 100 }}>
        <Dropdown direction="right" isOpen={shouldShowDropdown && !unopenable} toggle={this.toggleDropdown} onClick={onClick}>
          <DropdownToggle
            tag="span"
            style={{ fontSize: 22 }}
          >
            <span className={classnames('fa-sticky-note cursor-pointer', { 'text-info fas': value, 'text-muted far': !value })} />
          </DropdownToggle>
          <DropdownMenu>
            <div className="p-2" style={{ minWidth: 250 }}>
              <div>
                {
                  shouldShowForm ? (
                    <Form onSubmit={this.onSubmit} className="m-0">
                      <FormGroup>
                        <Input type="textarea" rows="6" value={formValue} onChange={this.onChangeText.bind(this, 'formValue')} />
                      </FormGroup>
                      <div className="d-flex justify-content-between">
                        <Button className="flex-grow-1" tag="span" onClick={this.cancel}>キャンセル</Button>
                        <Button className="ml-2 flex-grow-1" color="primary" onClick={this.onSubmit} disabled={!formValue || isSubmitting}>保存</Button>
                      </div>
                    </Form>
                  ) : (
                    <div>
                      <div className="cursor-pointer" onClick={(isPublic || writable) ? this.openForm : _ => _} dangerouslySetInnerHTML={{ __html: nl2br(autoLink(value, { linkAttr: { target: '_blank' } })) }} />
                      {
                        (isPublic || writable) && (
                          <div className="mt-2 d-flex justify-content-end">
                            <span className="cursor-pointer text-danger ml-2" onClick={this.onClickDelete}>
                              <span className="fas fa-trash mr-1" />
                              削除
                            </span>
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
};
