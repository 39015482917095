import React, { Component, Fragment, useState, useEffect, useRef, useMemo, } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Input, Nav, NavItem, NavLink, } from 'reactstrap';
import numeral from 'numeral';
import { mapValues, omit, pickBy, mergeWith, last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';
import { useCounter, useToggle, useDebounce, useMap, useAsync, } from 'react-use';
import { useLocation, useHistory } from 'react-router-dom';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { ResizableBox } from 'react-resizable';

import firebase, { functions } from '../../firebase';
import { batch, getCollectionData, getAllCollectionDataByChunk, } from '../../shared/firebase';
import { dimensionsOfCompany, enabledBudgetSubjectTypes, } from '../../shared/models/company';
import { dimensions, breakdownItemsLimitUnit, documentTypes, budgetSubjectTypes, } from '../../shared/config';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, readFile, fullPathWithParams, trialItemsToRowItems, computeSegmentedTrialAmount, computeBudget, pickSearch, filterRowsByAmount, groupSegmentedTrials, groupSegmentedTrialSubItems, } from '../../utils';
import { fields as budgetContainerFields, } from '../../shared/models/budgetContainer';
import { canWriteNote, } from '../../abilities';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import useMainTableUtilities from '../hooks/useMainTableUtilities';
import HelpLink from '../HelpLink';
import TrialQueriesSection from '../TrialQueriesSection';
import ProgressButton from '../ProgressButton';
import AddButton from '../AddButton';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';
import UserDisplay from '../UserDisplay';
import ModelFormModal from '../modals/ModelFormModal';
import CompanyPublicPage from '../hocs/CompanyPublicPage';
import CommentsDropdown from '../CommentsDropdown';
import QuerySelector from '../QuerySelector';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import Row from './CompanyMonthlyBudgets/Row';
import AllCollapseButton from '../AllCollapseButton';

const fetchPublicPageData = functions.httpsCallable('fetchPublicPageData', { timeout: 550000 });
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const maxContainersCount = 12;
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率', isRate: true, },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率', isRate: true, },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const displayColumnTypes = {
  month: { label: '月', },
  quarter: { label: '四半期', },
  half: { label: '半期', },
};
const displayColumnTypeOptions = entries(displayColumnTypes).map(([k, v]) => ({ label: v.label, value: k }));

export default CompanyPublicPage(CompanyPublicMonthlyBudgets);

function CompanyPublicMonthlyBudgets(props) {
  const { company, company: { sourceId: companySourceId }, period, targetMonth, } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const {
    publicKey,
    documentType = 'pl',
    dimension = 'none',
    subjectType: budgetSubjectType,
    budgetContainerId,
    comparedBudgetContainerId,
    itemNamesForFilter,
    subItemNamesForFilter,
    displayColumnTypes,
  } = queryParams;
  const {
    onClickComment,
    toggleCommenter,
    toggleInitiallyCommentRow,
    subRowsVisibilities,
    setAllSubRowsVisibilities,
    setSubRowsVisibilities,
    setHoveredCommenters,
    onRowsChanged,
  } = useMainTableUtilities();
  const [leftColumnWidth, setLeftColumnWidth] = useState(COLUMN_WIDTH * 2.5);
  const [allRows, setAllRows] = useState([]);
  const isSubFiltered = (queryParams.dimension || 'none') !== 'none' && queryParams.isSubFilter === '1';
  const rows = useMemo(_ => allRows.filter(_ => (_.accountItemCategory?.type === documentType && !_.accountItemCategory?.hidden) || _.isCustom), [allRows, documentType]);
  const [version, { inc: updateVersion }] = useCounter([]);
  const [processings, { set: setProcesssings }] = useMap({});
  const [subRowsBreakdownItemsLimitRates, { set: setSubRowsBreakdownItemsLimitRates }] = useMap({});
  const { freeeCollectionName, freeeSubjectIdsName, } = budgetSubjectTypes[budgetSubjectType];
  const dimensionOptions = entries(dimensionsOfCompany(company)).filter(_ => !['sections', ...(budgetSubjectType === 'section' ? [] : ['segment1s', 'segment2s', 'segment3s'])].includes(_[0])).map(([k, v]) => ({ label: v, value: k }));
  const [isCommentRowToggled, toggleCommentRowToggled] = useToggle();
  const [isSettingRows, toggleSettingRows] = useToggle(false);
  const [isFetchingLastTrials, toggleFetchingLastTrials] = useToggle();
  const [isExporting, toggleExporting] = useToggle();
  const [showsModal, toggleModal] = useToggle();
  const { start_date: startDate, use_industry_template: usesIndustry, } = fiscalYearOfPeriod(period, company.fiscalYears);
  const prevYearMonths = range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _)));
  const currentYearMonths = range(0, 12).map(_ => endOfMonth(addMonths(startDate, _)));
  const { value: data, } = useAsync(async () => {
    const { data } = await fetchPublicPageData({ screenType: 'monthlyBudgets', subjectType: budgetSubjectType, publicKey, period, targetMonth, documentType, dimension, budgetContainerId, comparedBudgetContainerId, trialStart: formatDate(prevYearMonths[0], 'YYYY-MM-DD'), trialEnd: formatDate(last(currentYearMonths), 'YYYY-MM-DD'), });
    return data;
  }, [publicKey, budgetSubjectType, period, targetMonth, documentType, dimension, budgetContainerId, comparedBudgetContainerId, version]);
  const { budgetSubjects, freeeSubjects, customAccountItems, accountItems, budgetContainers, budgets, comparedBudgets, trials, trialSubItems, notes, screenQueries, accountItemsOrderSetting, } = useMemo(() => {
    const { budgetSubjects, freeeSubjects = [], customAccountItems = [], accountItems = [], budgetContainers = [], budgets = [], comparedBudgets = [], trials = [], trialSubItems = {}, notes = [], screenQueries = [], accountItemsOrderSetting, } = data || {};
    const _trialSubItems = entries(trialSubItems).flatMap(([closingDate, _]) => entries(_).flatMap(([itemKey, _]) => entries(_).flatMap(([subjectId, _]) => _.map(([name, closing_balance]) => ({ name, closing_balance, subjectId, itemKey, closingDate })))));
    return { budgetSubjects, freeeSubjects, customAccountItems, accountItems, budgetContainers, budgets, comparedBudgets, trials, trialSubItems: _trialSubItems, notes, screenQueries, accountItemsOrderSetting, };
  }, [data]);
  const freeeSubjectsById = keyBy(freeeSubjects, 'id');
  const sortedBudgetContainers = orderBy(budgetContainers, 'index');
  const budgetsGroupedByClosingDate = groupBy(budgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const budgetsGroupedItemKey = groupBy(budgets, 'itemKey');
  const comparedBudgetsGroupedItemKey = groupBy(comparedBudgets, 'itemKey');
  const trialsGroupedByClosingDate = groupBy(trials, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubjectRowsGroupedByItemKey = groupSegmentedTrials(trials.flatMap(t => t[freeeCollectionName].map(_ => ({ ...t, ...omit(_, 'id'), subjectId: _.id, }))));
  const trialSubItemsGroupedByClosingDate = groupBy(trialSubItems, _ => formatDate(_.closingDate, 'YYYYMM'));
  const trialSubItemsGroupedByItemKey = groupSegmentedTrialSubItems(trialSubItems);
  const commentsGroupedByCommenteeKey = groupBy([], 'commenteeKey');
  const notesByKey = keyBy(notes, 'noteKey');
  const toggleTab = (documentType) => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, documentType, }));
  };
  const budgetsGroupedByItemKey = groupBy(budgets, 'itemKey');
  const relatedCustomAccountItems = customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
  const budgetContainerOptions = sortedBudgetContainers.map(_ => ({ label: _.name, value: _.id }));
  const itemNameOptions = uniq(rows.map(_ => _.itemName)).map(_ => ({ label: _, value: _ }));
  const subItemNameOptions = uniq(rows.map(_ => _.subItemName).filter(_ => _)).map(_ => ({ label: _, value: _ }));
  const generateMonthsItems = (options) => {
    const { budgetsGroupedByClosingDate } = options;
    return [prevYearMonths, currentYearMonths].map((months) => {
      return months.map((closingDate) => {
        const balances = trialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = budgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const trialSubItems = (options?.trialSubItemsGroupedByClosingDate || trialSubItemsGroupedByClosingDate)[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          trialSubItemsGroupedByItemKey: groupBy(trialSubItems, 'itemKey'),
          dimension,
        };
      });
    });
  };
  const monthsItems = generateMonthsItems({ budgetsGroupedByClosingDate });
  const prevYearMonthItemsByMonth = keyBy(monthsItems[0], _ => formatDate(_.closingDate, 'MM'));
  const generateRows = (options = {}) => {
    const { isAll = false } = options;
    const items = trialItemsToRowItems(monthsItems.flat(), { targetMonth, accountItems, customAccountItems: relatedCustomAccountItems, screenType: 'sectionBudgets', documentType, itemType: documentType, dimension, budgetSubjectsFreeeName: freeeCollectionName, sortsAllPlAndCrCategories: true, accountItemsOrderSetting, })
    const mainItems = items.filter(_ => !_.isCategory && !_.isSubRow && !_.isCustom);
    const rows = items
      .map(itemToRow.bind(null, mainItems, { isAll, }))
      .filter(_ => _.columns.some(_ => abs(_.amount) > 0 || abs(_.budgetAmount) > 0 || abs(_.prevYearAmount) > 0));
    return entries(groupBy(
      Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
        return [mainItem, ...orderBy(subItems, _ => Math.abs(_.monthColumnsByYearMonth[targetMonth].amount), 'desc')];
      }).flat()
    , 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const _subRows = subRows.map((row, i) => {
          return {
            ...row,
            breakDownItemIndex: i,
            subItemsCount: subRows.length,
          };
        });
        return [
          ...x,
          { ...mainRow, subRows: _subRows },
          ..._subRows
        ];
      }, []);
  };
  const computeOverMonth = (date) => {
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  };
  const quarters = chunk(currentYearMonths, 3);
  const halfs = chunk(currentYearMonths, 6);
  const itemToRow = (mainItems, { isAll }, item) => {
    const { isCustom = false, accountItemCategory, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const accountCategoryName = accountItemName ? null : item.account_category_name || item.accountCategoryName;
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const monthColumns = monthsItems[1].map((monthItem, i) => {
      const { closingDate, } = monthItem;
      const isOverMonth = computeOverMonth(closingDate);
      const prevYearMonthItem = prevYearMonthItemsByMonth[formatDate(closingDate, 'MM')];
      const { closingDate: prevYearClosingDate, } = prevYearMonthItem;
      const yearMonth = formatDate(closingDate, 'YYYYMM');
      const key = `${rowKey}_${yearMonth}`;
      const budgetSubjectRows = budgetSubjects.map((budgetSubject) => {
        const key = `${rowKey}_${yearMonth}_${budgetSubject?.id}`;
        const freeeSubjects = budgetSubject?.[freeeSubjectIdsName].map(_ => freeeSubjectsById[_]).filter(_ => _);
        const commenteeKey = [rowKey, budgetSubject?.id, yearMonth].join('_');
        const noteKey = [rowKey, budgetSubject?.id, yearMonth].join('_');
        const amountBase = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [closingDate], customAccountItems, budgetSubject?.[freeeSubjectIdsName], budgetSubject?.id);
        const amount = isOverMonth ? 0 : amountBase;
        const prevYearAmount = isOverMonth ? 0 : computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, [prevYearClosingDate], customAccountItems, budgetSubject?.[freeeSubjectIdsName], budgetSubject?.id);
        const budgetAmount = computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, [closingDate], customAccountItems, budgetSubject?.id, mainItems);
        const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, [closingDate], customAccountItems, budgetSubject?.id, mainItems);
        const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
        const budgetDiff = isOverMonth ? null : amount - budgetAmount;
        const achievementRate = amount / budgetAmount;
        const estimatedAmount = isOverMonth ? budgetAmount : amountBase;
        const prevYearDiff = amount - prevYearAmount;
        const prevYearDiffRate = prevYearDiff / prevYearAmount;
        const note = (notesByKey[noteKey] || {}).value;
        return { budgetSubject, key, commenteeKeys: [commenteeKey], noteKeys: [noteKey], closingDate, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, freeeSubjects, amountBase, };
      });
      const budgetSubjectsSummaryRow = {
        key: `${rowKey}_${yearMonth}_summary`,
        isSummaryColumn: true,
        label: '合計',
        closingDate,
        yearMonth,
        amount: sumBy(budgetSubjectRows, 'amount'),
        budgetAmount: sumBy(budgetSubjectRows, 'budgetAmount'),
        comparedBudgetAmount: sumBy(budgetSubjectRows, 'comparedBudgetAmount'),
        diffBetweenBudgets: sumBy(budgetSubjectRows, 'diffBetweenBudgets'),
        budgetDiff: sumBy(budgetSubjectRows, 'budgetDiff'),
        achievementRate: sumBy(budgetSubjectRows, 'amount') / sumBy(budgetSubjectRows, 'budgetAmount'),
        estimatedAmount: sumBy(budgetSubjectRows, 'estimatedAmount'),
        prevYearAmount: sumBy(budgetSubjectRows, 'prevYearAmount'),
        prevYearDiff: sumBy(budgetSubjectRows, 'prevYearDiff'),
        prevYearDiffRate: sumBy(budgetSubjectRows, 'prevYearDiff') / sumBy(budgetSubjectRows, 'prevYearAmount'),
      };
      const freeeSubjects = budgetSubjectRows.flatMap(_ => _.freeeSubjects);
      const commenteeKeys = budgetSubjectRows.map(_ => _.commenteeKeys[0]);
      const noteKeys = budgetSubjectRows.map(_ => _.noteKeys[0]);
      const [amountBase, amount, prevYearAmount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, estimatedAmount, prevYearDiff] = ['amountBase', 'amount', 'prevYearAmount', 'budgetAmount', 'comparedBudgetAmount', 'diffBetweenBudgets', 'budgetDiff', 'estimatedAmount', 'prevYearDiff'].map(_ => sumBy(budgetSubjectRows, _));
      const achievementRate = amount / budgetAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const notes = noteKeys.map(_ => (notesByKey[_] || {}).value);
      return { key, columnType: 'month', csvLabel: '_' + yearMonth, commenteeKeys, noteKeys, closingDate, yearMonth, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, notes, note: notes.join('\n'), freeeSubjects, amountBase, budgetSubjectRows, budgetSubjectsSummaryRow, };
    });
    const monthColumnsByYearMonth = keyBy(monthColumns, 'yearMonth');
    const prevYearMonthsByMm = keyBy(prevYearMonths, _ => formatDate(_, 'MM'));
    const computeSummaryColumn = (columnType, termMonths, termIndex) => {
      const notOverTermMonths = termMonths.filter(_ => !computeOverMonth(_));
      const notOverPrevYearTermMonths = notOverTermMonths.map(_ => prevYearMonthsByMm[formatDate(_, 'MM')]);
      const termMonthColumns = termMonths.map(_ => monthColumnsByYearMonth[formatDate(_, 'YYYYMM')]);
      const key = `${rowKey}_${termMonths.map(_ => formatDate(_, 'YYYYMM')).join(',')}`;
      const amount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, notOverTermMonths, customAccountItems, budgetSubjects.flatMap(_ => _?.[freeeSubjectIdsName]), budgetSubjects.map(_ => _.id));
      const prevYearAmount = computeSegmentedTrialAmount(budgetSubjectType, item, trialSubjectRowsGroupedByItemKey, trialSubItemsGroupedByItemKey, dimension, notOverPrevYearTermMonths, customAccountItems, budgetSubjects.flatMap(_ => _?.[freeeSubjectIdsName]), budgetSubjects.map(_ => _.id));
      const budgetAmount = computeBudget(budgetSubjectType, item, budgetsGroupedByItemKey, dimension, termMonths, customAccountItems, budgetSubjects.map(_ => _.id), mainItems);
      const comparedBudgetAmount = computeBudget(budgetSubjectType, item, comparedBudgetsGroupedItemKey, dimension, termMonths, customAccountItems, budgetSubjects.map(_ => _.id), mainItems);
      const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
      const budgetDiff = isCustom ? (termMonths.some(computeOverMonth) ? null : amount - budgetAmount) : amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = isCustom ? (termMonths.some(computeOverMonth) ? null : amount) : sumBy(termMonthColumns, 'estimatedAmount');
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      return { key, isSummaryColumn: true, csvLabel: ({ quarter: `${termIndex+1}Q`, half: `${({ 0: '上', 1: '下' })[termIndex]}半期` })[columnType], columnType, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, };
    };
    const quarterColumns = quarters.map(computeSummaryColumn.bind(null, 'quarter'));
    const halfColumns = halfs.map(computeSummaryColumn.bind(null, 'half'));
    const columns = chunk(chunk(monthColumns, 3), 2).flatMap((halfQuarters, halfIndex) => [...halfQuarters.flatMap((monthColumns, quarterIndex) => [...monthColumns, quarterColumns[halfIndex * 2 + quarterIndex]]), halfColumns[halfIndex]]);
    const totalAmount = sumBy(monthColumns, 'amount');
    const totalBudgetAmount = sumBy(monthColumns, 'budgetAmount');
    const totalBudgetDiff = sumBy(monthColumns, 'budgetDiff');
    const totalComparedBudgetAmount = sumBy(monthColumns, 'comparedBudgetAmount');
    const totalDiffBetweenBudgets = sumBy(monthColumns, 'diffBetweenBudgets');
    const totalEstimatedAmount = sumBy(monthColumns, 'estimatedAmount');
    const totalPrevYearAmount = sumBy(monthColumns, 'prevYearAmount');
    const totalPrevYearDiff = sumBy(monthColumns, 'prevYearDiff');
    const summaryColumns = [
      {
        isSummaryColumn: true,
        name: 'sum',
        key: `${rowKey}_sum`,
        commenteeKey: `${rowKey}_sum`,
        amount: totalAmount,
        budgetAmount: totalBudgetAmount,
        comparedBudgetAmount: totalComparedBudgetAmount,
        diffBetweenBudgets: totalDiffBetweenBudgets,
        budgetDiff: totalBudgetDiff,
        achievementRate: totalAmount / totalBudgetAmount,
        estimatedAmount: totalEstimatedAmount,
        prevYearAmount: totalPrevYearAmount,
        prevYearDiff: totalPrevYearDiff,
        prevYearDiffRate: totalPrevYearDiff / totalPrevYearAmount,
      },
    ];
    const comments = monthColumns.flatMap(_ => _.commenteeKeys.flatMap(_ => commentsGroupedByCommenteeKey[_] || []));
    return {
      ...item,
      budgetSubjects,
      accountItemCategory,
      accountItemName,
      accountCategoryName,
      hierarchyLevel,
      isCategory,
      isCustom,
      itemName,
      itemKey,
      mainRowItemKey,
      key: rowKey,
      subItemName,
      isSubRow,
      rowName,
      displayExpression,
      monthColumns,
      monthColumnsByYearMonth,
      columns,
      summaryColumns,
      comments,
    };
  }
  const rowsDependencies = [budgetSubjects?.map(_ => _.id).join(''), budgetContainerId, comparedBudgetContainerId, trials, trialSubItems, budgets, targetMonth, comparedBudgets, documentType];
  useEffect(_ => toggleSettingRows(true), rowsDependencies);
  useDebounce(async () => {
    setAllRows(generateRows());
    toggleSettingRows(false);
  }, 1000, rowsDependencies);

  const filterRows = (rows) => {
    let filteredRows = rows;
    if(!isEmpty(itemNamesForFilter)) {
      filteredRows = filteredRows.filter(_ => itemNamesForFilter.includes(_.itemName));
    }
    if(dimension === 'none') {
      entries(metrics).map(([metric, { isRate }]) => {
        filteredRows = filterRowsByAmount(filteredRows, queryParams, metric, _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
      });
    } else {
      filteredRows = entries(groupBy(filteredRows, 'itemKey'))
        .map(([itemKey, [mainRow, ...subRows]]) => {
          let filteredSubRows = subRows;
          if(!isEmpty(subItemNamesForFilter)) {
            filteredSubRows = filteredSubRows.filter(row => subItemNamesForFilter.some(_ => row.subItemName === _));
          }
          isSubFiltered && entries(metrics).map(([metric, { isRate }]) => {
            filteredSubRows = filterRowsByAmount(filteredSubRows, queryParams, metric, _ => _.columns.find(_ => _.yearMonth === targetMonth.toString())?.[metric] * (isRate ? 100 : 1));
          });
          return {
            mainRow: (
              subRows.length === 0 ? mainRow : {
                ...mainRow,
                columns: mainRow.columns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.columns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.columns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.columns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.columns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
                summaryColumns: mainRow.summaryColumns.map((column, i) => {
                  const amount = !isSubFiltered ? column.amount : sumBy(filteredSubRows, _ => _.columns[i].amount);
                  const prevYearAmount = !isSubFiltered ? column.prevYearAmount : sumBy(filteredSubRows, _ => _.columns[i].prevYearAmount);
                  const budgetAmount = !isSubFiltered ? column.budgetAmount : sumBy(filteredSubRows, _ => _.columns[i].budgetAmount);
                  const comparedBudgetAmount = !isSubFiltered ? column.comparedBudgetAmount : sumBy(filteredSubRows, _ => _.columns[i].comparedBudgetAmount);
                  const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
                  const budgetDiff = amount - budgetAmount;
                  const achievementRate = amount / budgetAmount;
                  const estimatedAmount = !isSubFiltered ? column.estimatedAmount : sumBy(filteredSubRows, _ => _.columns[i].estimatedAmount);
                  const prevYearDiff = amount - prevYearAmount;
                  const prevYearDiffRate = prevYearDiff / prevYearAmount;
                  return {
                    ...column,
                    amount,
                    prevYearAmount,
                    budgetAmount,
                    comparedBudgetAmount,
                    diffBetweenBudgets,
                    budgetDiff,
                    achievementRate,
                    estimatedAmount,
                    prevYearDiff,
                    prevYearDiffRate,
                  };
                }),
              }
            ),
            subRows: filteredSubRows.map((row, i) => {
              return {
                ...row,
                breakDownItemIndex: i,
                subItemsCount: filteredSubRows.length,
              };
            }),
          };
        })
        .filter(_ => !isSubFiltered || _.subRows.length > 0)
        .reduce((x, y) => [...x, y.mainRow, ...y.subRows], []);
    }
    return filteredRows;
  };
  const filteredRows = filterRows(rows);

  // NOTE: タブが切り替わったあと、budgetSubjectsが一旦空になるまで1周待つ必要がある。非同期にすれば解決するので、useDebounceを用いる。
  useDebounce(() => {
    const path = fullPathWithParams({
      dimension: dimension || 'none',
      budgetContainerId: sortedBudgetContainers.some(_ => _.id === budgetContainerId) ? budgetContainerId : get(sortedBudgetContainers, [0, 'id']),
    }, location);
    history.replace(encodeURI(path));
  }, 1000, [documentType, budgetContainers]);
  useEffect(() => {
    toggleInitiallyCommentRow(rows);
    onRowsChanged();
  }, [rows]);

  return (
    <div className="company-monthly-budgets">
      <div className="px-3 py-3">
        <div className="mb-2">
          <h3 className="text-center">予実月次推移</h3>
          <h5 className="text-center text-muted">{budgetSubjects?.map(_ => _.name).join(', ')}</h5>
        </div>
        <Nav tabs>
          {
            entries(pick(documentTypes, ['pl', 'cr'])).map(([_documentType, { label: documentTypeLabel }]) => {
              return (
                <NavItem key={_documentType}>
                  <NavLink className={classnames('cursor-pointer', { active: _documentType === documentType })} onClick={toggleTab.bind(null, _documentType)}>
                    {documentTypeLabel}
                  </NavLink>
                </NavItem>
              );
            })
          }
        </Nav>
        <div className="py-3">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <TrialQueriesSection 
              company={company}
              readOnly
              screenQueries={screenQueries.filter(_ => _.screenType === 'monthlyBudgets')}
              screenType="monthlyBudgets"
              uncontrols={['budgetSubjectId']}
              items={[
                [
                  {
                    name: 'metrics',
                    type: 'multiSelect',
                    options: metricOptions,
                    label: '数値項目',
                    props: { width: 200, }
                  },
                  {
                    name: 'itemNamesForFilter',
                    type: 'multiSelect',
                    options: itemNameOptions,
                    label: '科目名',
                    isFront: true,
                    props: { width: 300, }
                  },
                  {
                    name: 'dimension',
                    type: 'select',
                    options: dimensionOptions,
                    label: '明細',
                    isFront: true,
                    props: { width: 150, selectorProps: { isClearable: false } },
                  },
                ],
                [
                  ...(
                    dimension !== 'none' ? [
                      {
                        name: 'isSubFilter',
                        type: 'boolean',
                        label: '明細単位で検索する',
                      },
                    ] : []
                  ),
                  ...(
                    dimension !== 'none' && queryParams.isSubFilter === '1' ? [
                      {
                        name: 'subItemNamesForFilter',
                        type: 'multiSelect',
                        options: subItemNameOptions,
                        label: '明細名',
                        props: { width: 300, }
                      },
                    ] : []
                  ),
                ],
                [
                  {
                    name: 'budgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'comparedBudgetContainerId',
                    type: 'select',
                    options: budgetContainerOptions,
                    label: '比較予算',
                    isFront: true,
                    props: { width: 250, },
                  },
                  {
                    name: 'displayColumnTypes',
                    type: 'multiSelect',
                    options: displayColumnTypeOptions,
                    label: '表示列',
                    isFront: true,
                    props: { width: 250, },
                  },
                ],
                ...entries(metrics).map(([metric, { label, isRate }]) => {
                  return [
                    {
                      name: metric + 'Min',
                      type: 'numeric',
                      label: label + '下限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                    {
                      name: metric + 'Max',
                      type: 'numeric',
                      label: label + '上限' + (isRate ? '(%)' : ''),
                      props: { width: 200, }
                    },
                  ];
                })
              ]}
            />
          </div>
          {
            budgetSubjects != null && (
              <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
                <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
                  <thead className="text-center" style={{ lineHeight: '20px' }}>
                    <tr>
                      <th className="text-left border-right p-0">
                        <ResizableBox className="p-2 d-flex align-items-center" width={COLUMN_WIDTH * 2.5} height={44} axis="x" resizeHandles={['e']} onResize={(e, _) => setLeftColumnWidth(_.size.width)}>
                          {
                            dimension !== 'none' && (
                              <AllCollapseButton size="sm" className="small px-0" color="link" rows={filteredRows} visibilities={subRowsVisibilities} setVisibilities={setAllSubRowsVisibilities} />
                            )
                          }
                          &nbsp;
                        </ResizableBox>
                      </th>
                      {
                        chunk(chunk(monthsItems[1], 3), 2).map((halfQuarters, halfIndex) => {
                          return (
                            <Fragment key={halfIndex}>
                              {
                                halfQuarters.map((monthItems, quarterIndex) => {
                                  return (
                                    <Fragment key={quarterIndex}>
                                      {
                                        (isEmpty(displayColumnTypes) || displayColumnTypes.includes('month')) && monthItems.map(({ closingDate, exists }, i) => {
                                          const isProccessing = processings[formatDate(closingDate, 'YYYYMM')] || false;
                                          return (
                                            <th key={i} className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                                              <Link to={`/p/monthBudgets?${qs.stringify({ subjectType: budgetSubjectType, publicKey, documentType, period, targetMonth: formatDate(closingDate, 'YYYYMM'), })}`}>
                                                {formatDate(closingDate, 'YYYY/MM')}
                                              </Link>
                                            </th>
                                          );
                                        })
                                      }
                                      {
                                        (isEmpty(displayColumnTypes) || displayColumnTypes.includes('quarter')) && (
                                          <th className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                                            {halfIndex * 2 + quarterIndex + 1}Q
                                          </th>
                                        )
                                      }
                                    </Fragment>
                                  );
                                })
                              }
                              {
                                (isEmpty(displayColumnTypes) || displayColumnTypes.includes('half')) && (
                                  <th className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                                    {({ 0: '上', 1: '下' })[halfIndex]}半期
                                  </th>
                                )
                              }
                            </Fragment>
                          );
                        })
                      }
                      <th style={{ width: COLUMN_WIDTH }}>合計</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      filteredRows.map((row) => {
                        const { isSubRow, itemKey, } = row;
                        const showsSubRows = (dimension !== 'none' && !!subRowsVisibilities[itemKey]);
                        if(isSubRow && !showsSubRows) return null;

                        const breakdownItemsLimitRate = subRowsBreakdownItemsLimitRates[itemKey] || 1;
                        const toggleSubRows = setSubRowsVisibilities.bind(null, itemKey, !showsSubRows);
                        const showMoreSubRows = setSubRowsBreakdownItemsLimitRates.bind(null, itemKey, breakdownItemsLimitRate + 1);
                        return <Row isPublic updateVersion={updateVersion} pageProps={props} row={row} {...{ company, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, leftColumnWidth, }} />
                      })
                    }
                  </tbody>
                </Table>
                <OverlayLoading isOpen={isSettingRows} />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
