import React, { useState, useEffect, } from 'react';
import { Input } from 'reactstrap';
import { useLocation, useHistory, } from 'react-router';
import qs from 'qs';
import { useDebounce, useToggle, } from 'react-use';
import classnames from 'classnames';
import numeral from 'numeral';
import moji from 'moji';
import { isEmpty } from 'lodash';

import useQueryParams from './hooks/useQueryParams';
import { fullPathWithParams, } from '../utils';


export default function QueryInput(props) {
  const { type = 'string', paramName = 'filter', label = '絞込み', width = 200, disabled = false, inputClassName = '', inputProps, valueOfChange = _ => _.target.value, ...extraProps } = props;
  const location = useLocation();
  const history = useHistory();
  const { [paramName]: queryValue = '' } = useQueryParams();
  const [isActive, toggleActive] = useToggle(false);
  const [value, setValue] = useState(queryValue);
  const formatValue = (value) => {
    return ({
      string: value,
      integer: numeral(value).format('0,0'),
      float: numeral(value).format('0,0[.][000000]'),
    })[type];
  };
  const [localValue, setLocalValue] = useState((({
    string: _ => value,
    integer: _ => value != null ? formatValue(value) : null,
    float: _ => value != null ? formatValue(value) : null,
  })[type] || (() => null))());
  const parse = ({
    integer: (value) => value === '' ? null : numeral(formatValue(moji(value).convert('ZE', 'HE').toString())).value(),
    float: (value) => value === '' ? null : numeral(formatValue(moji(value).convert('ZE', 'HE').toString())).value(),
  })[type] || (_ => _);
  const onChange = ({ target }) => {
    setLocalValue(target.value);
    setValue(parse(target.value));
  };
  const onFocus = () => {
    toggleActive(true);
  };
  const onBlur = () => {
    toggleActive(false);
    setLocalValue(value != null ? formatValue(value) : '');
  };
  useDebounce(() => {
    const path = fullPathWithParams({ [paramName]: value }, location);
    history.replace(encodeURI(path));
  }, 500, [value]);
  useEffect(() => {
    if(!isActive) {
      setLocalValue(!isEmpty(queryValue) ? formatValue(queryValue) : '');
    }
  }, [isActive, queryValue]);

  return (
    <div style={{ width, }} {...extraProps}>
      <div className="text-muted small">{label}</div>
      <Input
        value={localValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classnames('w-100', inputClassName)}
        disabled={disabled}
        {...inputProps}
      />
    </div>
  );
};
