import React, { Component, Fragment, useState, } from 'react';
import { Table, Button, Nav, NavItem, NavLink,  } from 'reactstrap';
import { last, chunk, uniqBy, orderBy, uniq, isEqual, get, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, range, debounce, pick, } from 'lodash';
import numeral from 'numeral';
import { toast } from 'react-toastify';
import { useToggle, useDebounce, useMap, } from 'react-use';
import { Container, Draggable } from 'react-smooth-dnd';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addYears, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';

import { breakdownItemsLimitUnit, } from '../../../shared/config';
import useQueryParams from '../../hooks/useQueryParams';
import SyncCustomAccountItemManualValuesButton from '../../SyncCustomAccountItemManualValuesButton';
import AccountItemDisplay from '../../AccountItemDisplay';
import Column from './Column';

const suffix = documentType => ({ pl: '', cr: 'Cr' })[documentType];
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';

export default function Row (props) {
  const { company, pageProps, row, commentsGroupedByCommenteeKey, notesByKey, budgetSubjectType, setHoveredCommenters, metrics, toggleSubRows, breakdownItemsLimitRate, showsSubRows, showMoreSubRows, isPublic = false, updateVersion, leftColumnWidth, } = props;
  const { hierarchyLevel = 1, key, isCategory, isCustom, isManual, itemName, itemKey, subItemName, isSubRow, rowName, columns, summaryColumns, diff, displayExpression, breakDownItemIndex, subItemsCount, } = row;
  const { role, targetMonth, accountItemSettingsById, user, members, period, match: { params: { companyId } } } = pageProps;
  const queryParams = useQueryParams();
  const { tabKey = 'section__pl', dimension = 'none', aggregationType = 'cumulative', metrics: metricsForFilter = keys(metrics), comparedBudgetContainerId, } = queryParams;
  const isComparing = !isEmpty(comparedBudgetContainerId);
  const allColumns = [...columns.filter(_ => isEmpty(queryParams.displayColumnTypes) || queryParams.displayColumnTypes.includes(_.columnType)), ...summaryColumns];

  const accountItemSetting = accountItemSettingsById?.[`${rowName.replace(/\//g, '_')}__${period}`];
  const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
  const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
  if(shouldHide) return null;
  const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
  const leftSubItemsCount = subItemsCount - showingCount;

  return (
    <tr key={itemKey + key} className={classnames({ 'sub-row': isSubRow })}>
      <th className="border-right" style={{ width: leftColumnWidth, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} className={classnames({ 'table-danger': !isSubRow && abs(diff) > 0 })} colSpan={5 - hierarchyLevel}>
        <div className="d-flex justify-content-between align-items-start">
          <div>
            {
              dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                <Button size="sm" className="small px-0 mr-1" color="link" onClick={toggleSubRows}>
                  <span className={classnames('fas cursor-pointer', { 'fa-plus': !showsSubRows, 'fa-minus': showsSubRows })} />
                </Button>
              )
            }
            <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
            {isCustom && isManual && <SyncCustomAccountItemManualValuesButton color="link" size="sm" company={company} />}
            {
              isLastSubRow && leftSubItemsCount > 0 && (
                <div className="mt-2">
                  <Button size="sm" onClick={showMoreSubRows.bind(null, itemKey)}>
                    もっと見る (残り{leftSubItemsCount}件)
                  </Button>
                </div>
              )
            }
          </div>
          <div className="text-nowrap">
            {
              metricsForFilter.filter(_ => !(!isComparing && ['comparedBudgetAmount', 'diffBetweenBudgets'].includes(_))).map((metric) => {
                const { label } = metrics[metric];
                return (
                  <div key={metric} style={{ lineHeight: AMOUNT_HEIGHT }}>
                    {label}
                  </div>
                );
              })
            }
          </div>
        </div>
      </th>
      {
        allColumns.map((column, index) => {
          const { key, } = column;
          return (
            <Column
              key={key}
              _key={key}
              commenterRef={(keys, el) => setHoveredCommenters(keys, el)}
              isPublic={isPublic}
              updateVersion={updateVersion}
              {...{ ...column, column, metrics, company, role, metricsForFilter, isComparing, index, isCategory, isCustom, user, members, isSubRow, itemName, dimension, subItemName, period, companyId, commentsGroupedByCommenteeKey, notesByKey, displayExpression, yearMonth: targetMonth, budgetSubjectType, }}
            />
          );
        })
      }
    </tr>
  );
}
