import React, { Component, Fragment, useEffect, } from 'react';
import { Table, Button, Input, } from 'reactstrap';
import numeral from 'numeral';
import { uniqBy, get, orderBy, isEqual, sumBy, isEmpty, isNumber as _isNumber, groupBy, sortBy, keyBy, omit, range, debounce, pick, chunk } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { addYears, format as formatDate, startOfMonth, endOfMonth, addMonths, } from 'date-fns';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, parse as parseCsv } from 'papaparse';
import { TextEncoder, TextDecoder } from 'text-encoding';
import qs from 'qs';
import Select from 'react-select';

import firebase, { functions } from '../../firebase';
import { canWriteSectionTrial, canWriteSectionBudget, canWriteNote } from '../../abilities';
import { dimensions, breakdownItemsLimitUnit, } from '../../shared/config';
import { getCollectionData, } from '../../shared/firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useQueryParams from '../hooks/useQueryParams';
import HelpLink from '../HelpLink';
import CompanyPage from '../hocs/CompanyPage';
import AccountItemDisplay from '../AccountItemDisplay';
import OverlayLoading from '../OverlayLoading';
import CommentsDropdown from '../CommentsDropdown';
import HoveredCommenter from '../HoveredCommenter';
import HoveredNoter from '../HoveredNoter';
import QuerySelector from '../QuerySelector';
import { log, startOfMonthByFiscalYears, existsInFiscalYears, fiscalYearOfPeriod, readFile, fullPathWithParams, trialItemsToRowItems, computeSectionTrialAmount, computeSectionBudget, } from '../../utils';
import env from '../../env';
import './CompanyPeriodComparison.scss';

const fetchFreeePlSectionTrials = functions.httpsCallable('fetchFreeePlSectionTrials', { timeout: 550000 });
const db = firebase.firestore();
const auth = firebase.auth();
const companiesRef = db.collection('companies');
const { keys, entries, } = Object;
const { abs } = Math;
const isFiniteNumber = _ => _isNumber(_) && isFinite(_);
const COLUMN_WIDTH = 150;
const AMOUNT_HEIGHT = '24px';
const metrics = {
  budgetAmount: { label: '予算' },
  comparedBudgetAmount: { label: '比較予算' },
  diffBetweenBudgets: { label: '予算差額' },
  amount: { label: '当期実績' },
  budgetDiff: { label: '差額' },
  achievementRate: { label: '達成率' },
  estimatedAmount: { label: '着地見込' },
  prevYearAmount: { label: '前期実績' },
  prevYearDiff: { label: '増減額' },
  prevYearDiffRate: { label: '増減率' },
};
const metricOptions = entries(metrics).map(([k, v]) => ({ label: v.label, value: k }));
const aggregationTypes = {
  cumulative: { label: '累計', },
  single: { label: '単月', },
};
const aggregationTypeOptions = entries(aggregationTypes).map(([k, v]) => ({ label: v.label, value: k }));

export class CompanyMonthSectionBudgets extends Component {
  constructor() {
    super();
    this.state = { };
  }
  async componentDidMount() {
    await new Promise(_ => setTimeout(_, 100));
    this.listenSectionTrials();
  }
  componentWillUnmount() {
    this.unlistenSectionTrials && this.unlistenSectionTrials();
  }
  componentDidUpdate(prevProps, prevState) {
    if(
      ['period', 'targetMonth'].some(_ => prevProps[_] !== this.props[_])
      || ['dimension'].some(_ => this.queryParams(prevProps)[_] !== this.queryParams()[_])
    ) {
      this.unlistenSectionTrials && this.unlistenSectionTrials();
      this.listenSectionTrials();
    }
    if(
      ['sectionBudgets', 'comparedSectionBudgets', 'comments'].some(_ => prevProps[_] !== this.props[_])
      || ['sectionTrials'].some(_ => prevState[_] !== this.state[_])
      || ['customSectionId', 'aggregationType'].some(_ => !isEqual(this.queryParams(prevProps)[_], this.queryParams()[_]))
    ) {
      this.setState({ isSettingRows: true });
      this.setRowsDebounced();
    }
  }
  queryParams(props) {
    const { location: { search } } = props || this.props;
    return qs.parse(decodeURI(search.slice(1)), { arrayLimit: Infinity });
  }
  filteredRows = () => {
    const { rows = [], } = this.state;
    let filteredRows = rows;
    return entries(groupBy(filteredRows, 'itemKey'))
      .reduce((x, [itemKey, [mainRow, ...subRows]]) => {
        const showsSubRow = this.state[`shouldShowSubRows__${itemKey}`];
        return [
          ...x,
          mainRow,
          ...subRows.map((row, i) => {
            return {
              ...row,
              breakDownItemIndex: i,
              subItemsCount: subRows.length,
              shows: showsSubRow,
            };
          })
        ];
      }, []);
  }
  async fetch(closingDate) {
    const { company, sections = [], company: { sourceId: companySourceId, }, match: { params: { companyId } } } = this.props;
    const { dimension } = this.queryParams();
    const token = await auth.currentUser.getIdToken(true);
    try {
      await fetchFreeePlSectionTrials({ dimension, companyId, companySourceId, startDate: formatDate(startOfMonthByFiscalYears(closingDate, company), 'YYYY-MM-DD'), endDate: formatDate(closingDate, 'YYYY-MM-DD'), sectionIds: sections.map(_ => _.id) });
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
  }
  months() {
    const { period, targetMonth, company: { fiscalYears } } = this.props;
    const { start_date: startDate, } = fiscalYearOfPeriod(period, fiscalYears);
    return [
      range(-12, 0).map(_ => endOfMonth(addMonths(startDate, _))),
      range(0, 12).map(_ => endOfMonth(addMonths(startDate, _))),
    ];
  }
  monthItems(options = {}) {
    const { sectionTrialsGroupedByClosingDate = {}, } = this.state;
    const sectionBudgetsGroupedByClosingDate = options.sectionBudgetsGroupedByClosingDate || this.props.sectionBudgetsGroupedByClosingDate || {};
    const { dimension, } = this.queryParams();
    return this.months().map((months) => {
      return months.map((closingDate) => {
        const balances = sectionTrialsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const budgets = sectionBudgetsGroupedByClosingDate[formatDate(closingDate, 'YYYYMM')] || [];
        const exists = !isEmpty(balances);
        return {
          closingDate,
          exists,
          balances,
          balancesByItemKey: keyBy(balances, 'itemKey'),
          budgets,
          budgetsGroupedByItemKey: groupBy(budgets, 'itemKey'),
          dimension,
        };
      });
    });
  }
  listenSectionTrials = debounce(() => {
    const { match: { params: { companyId } } } = this.props;
    const { dimension, } = this.queryParams();
    const months = this.months();
    const [prevYearFirstEndDate] = months[0];
    const [lastEndDate] = months[1].slice(-1);
    this.unlistenSectionTrials = companiesRef
      .doc(companyId)
      .collection('sectionTrials')
      .where('dimension', '==', dimension)
      .where('closingDate', '>=', formatDate(prevYearFirstEndDate, 'YYYY-MM-DD'))
      .where('closingDate', '<=', formatDate(lastEndDate, 'YYYY-MM-DD'))
      .onSnapshot(debounce(({ docs }) => {
        const sectionTrials = docs.map(_ => _.data());
        const sectionTrialsGroupedByClosingDate = groupBy(sectionTrials, _ => formatDate(_.closingDate, 'YYYYMM'));
        const sectionTrialsGroupedByItemKey = groupBy(sectionTrials, 'itemKey');
        this.setState({ sectionTrials, sectionTrialsGroupedByClosingDate, sectionTrialsGroupedByItemKey, });
      }, 300));
  }, 300)
  setRowsDebounced = debounce(() => {
    const rows = this.generateRows();
    this.setState({ rows, isSettingRows: false });
  }, 500)
  generateRows = ({ customSection: _customSection, sectionBudgetsGroupedItemKey, sectionBudgetsGroupedByClosingDate, } = {}) => {
    const customSection = _customSection || this.currentCustomSection();
    if(!customSection) return [];

    const { targetMonth, company, accountItems = [], customAccountItems: _customAccountItems = [], sections = [], notesByKey, } = this.props;
    const { dimension, } = this.queryParams();
    const customAccountItems = _customAccountItems.filter(_ => (_.dimension || 'none') === 'none' || _.dimension === dimension);
    const sectionsById = keyBy(sections, 'id');
    const relatedSections = customSection.sectionIds.map(_ => sectionsById[_]).filter(_ => _);
    const [prevYearMonthItems, currentYearMonthItems] = this.monthItems({ sectionBudgetsGroupedByClosingDate });
    const prevYearMonthItemsByMonth = keyBy(prevYearMonthItems, _ => formatDate(_.closingDate, 'MM'));
    const allItems = [...currentYearMonthItems, ...prevYearMonthItems];
    const items = trialItemsToRowItems(allItems, { targetMonth, accountItems, customAccountItems, screenType: 'sectionBudgets', documentType: 'pl', itemType: 'pl', dimension, sectionTrials: true })
    const rows = items
      .map(this.itemToRow.bind(this, { company, sectionBudgetsGroupedItemKey, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, customSection, sectionsById, relatedSections, }))
      .filter(_ => [_.column].some(_ => ['amount', 'budgetAmount', 'comparedBudgetAmount', 'prevYearAmount'].some(k => abs(_[k]) > 0)));
    return Object.values(groupBy(rows, 'mainRowItemKey')).map(([mainItem, ...subItems]) => {
      return [mainItem, ...orderBy(subItems, _ => _.column.amount, 'desc')];
    }).flat();
  }
  isOverMonth = (date) => {
    const { targetMonth } = this.props;
    return date > endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _));
  }
  isWithinTargetMonth = (date) => {
    const { targetMonth } = this.props;
    const { aggregationType = 'cumulative', } = this.queryParams();
    return ({
      cumulative: date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
      single: startOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)) <= date && date <= endOfMonth(targetMonth.toString().replace(/\d{2}$/, _ => '-' + _)),
    })[aggregationType];
  }
  itemToRow = (options, item) => {
    const { targetMonth, } = this.props;
    const { company, customAccountItems, currentYearMonthItems, prevYearMonthItems, prevYearMonthItemsByMonth, notesByKey, customSection, sectionsById, relatedSections, } = options;
    const { isCustom = false, itemName, itemKey, isSubRow, subItemName, hierarchyLevel, accountItemName, displayExpression = '0,0', } = item;
    const { sectionTrialsGroupedByItemKey = {}, } = this.state;
    const sectionBudgetsGroupedItemKey = options.sectionBudgetsGroupedItemKey || this.props.sectionBudgetsGroupedItemKey || {};
    const comparedSectionBudgetsGroupedItemKey = options.comparedSectionBudgetsGroupedItemKey || this.props.comparedSectionBudgetsGroupedItemKey || {};
    const accountCategoryName = accountItemName ? null : item.account_category_name || item.accountCategoryName;
    const { dimension, aggregationType, } = this.queryParams();
    const [prevYearMonths, currentYearMonths] = this.months();
    const targetRangeMonths = currentYearMonths.filter(_ => this.isWithinTargetMonth(_));
    const prevYearTargetRangeMonths = targetRangeMonths.map(_ => addMonths(_, -12));
    const outRangeMonths = currentYearMonths.filter(_ => !this.isWithinTargetMonth(_));
    const isCategory = isEmpty(accountItemName);
    const rowKey = (isCustom ? 'cus__' : '') + (isCategory ? 'cat__' : '') + ((dimension !== 'none' && isSubRow) ? `${itemName}__${dimension}__${subItemName}` : itemName);
    const mainRowItemKey = isCustom ? itemKey : item.mainRowItemKey;
    const rowName = subItemName || itemName;
    const column = (() => {
      const sections = customSection.sectionIds.map(_ => sectionsById[_]).filter(_ => _);
      const key = `${rowKey}_${customSection.id}`;
      const commenteeKey = `${key}_${targetMonth}`;
      const noteKey = `${key}_${targetMonth}`;
      const amount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, targetRangeMonths, customAccountItems, customSection.sectionIds, [customSection.id]);
      const budgetAmount = computeSectionBudget(item, sectionBudgetsGroupedItemKey, dimension, targetRangeMonths, customAccountItems, [customSection.id]);
      const comparedBudgetAmount = computeSectionBudget(item, comparedSectionBudgetsGroupedItemKey, dimension, targetRangeMonths, customAccountItems, [customSection.id]);
      const diffBetweenBudgets = budgetAmount - comparedBudgetAmount;
      const outRangeBudgetAmount = computeSectionBudget(item, sectionBudgetsGroupedItemKey, dimension, outRangeMonths, customAccountItems, [customSection.id]);
      const budgetDiff = amount - budgetAmount;
      const achievementRate = amount / budgetAmount;
      const estimatedAmount = ({
        cumulative: _ => amount + outRangeBudgetAmount,
        single: _ => amount,
      })[aggregationType]();
      const prevYearAmount = computeSectionTrialAmount(item, sectionTrialsGroupedByItemKey, dimension, prevYearTargetRangeMonths, customAccountItems, customSection.sectionIds, [customSection.id]);
      const prevYearDiff = amount - prevYearAmount;
      const prevYearDiffRate = prevYearDiff / prevYearAmount;
      const note = get(notesByKey, noteKey, {}).value;
      return { key, commenteeKey, noteKey, customSection, amount, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, note, sections, };
    })();
    return { accountItemName, accountCategoryName, hierarchyLevel, isCategory, isCustom, itemName, itemKey, mainRowItemKey, key: rowKey, subItemName, isSubRow, rowName, column, displayExpression, };
  }
  onSelect = (name, { value }) => {
    const { history, location } = this.props;
    const path = fullPathWithParams({ [name]: value }, location);
    history.replace(encodeURI(path));
  }
  onClickComment = (commenteeKey) => {
    const { [`hovered_commenter__${commenteeKey}`]: commenter } = this;
    if(commenter) {
      commenter.open();
      commenter.focus();
    }
  }
  async sync(closingDate) {
    const { dimension, } = this.queryParams();
    this.setState({ [`isProccessing__${formatDate(closingDate, 'YYYYMM')}__${dimension}`]: true });
    await this.fetch(closingDate);
    this.setState({ [`isProccessing__${formatDate(closingDate, 'YYYYMM')}__${dimension}`]: false });
  }
  toggleSubRows(itemKey) {
    this.setState({ [`shouldShowSubRows__${itemKey}`]: !this.state[`shouldShowSubRows__${itemKey}`] });
    this.setRowsDebounced();
  }
  showMoreSubRows(itemKey) {
    this.setState({ [`breakdownItemsLimitRate_${itemKey}`]: (this.state[`breakdownItemsLimitRate_${itemKey}`] || 1) + 1 });
    this.setRowsDebounced();
  }
  renderRow({ hierarchyLevel = 1, key, isCategory, isCustom, itemName, itemKey, subItemName, isSubRow, rowName, column, diff, displayExpression, breakDownItemIndex, subItemsCount, }) {
    const { role, accountItemSettingsById, user, members, period, targetMonth, match: { params: { companyId } }, sections = [], isSummaryColumn, commentsGroupedByCommenteeKey, notesByKey, } = this.props;
    const customSection = this.currentCustomSection();
    const sectionsById = keyBy(sections, 'id');
    const relatedSections = customSection.sectionIds.map(_ => sectionsById[_]).filter(_ => _);
    const { dimension, aggregationType, comparedSectionBudgetContainerId, } = this.queryParams();
    const isComparing = !isEmpty(comparedSectionBudgetContainerId);
    const shouldShowSubRows = this.state[`shouldShowSubRows__${itemKey}`];
    const accountItemSetting = accountItemSettingsById[`${rowName.replace(/\//g, '_')}__${period}`];
    const breakdownItemsLimitRate = this.state[`breakdownItemsLimitRate_${itemKey}`] || 1;
    const showingCount = breakdownItemsLimitUnit * breakdownItemsLimitRate;
    const shouldHide = isSubRow && (breakDownItemIndex + 1) > showingCount;
    if(shouldHide) return null;

    const { key: monthKey, commenteeKey, noteKey, budgetAmount, comparedBudgetAmount, diffBetweenBudgets, amount, budgetDiff, achievementRate, estimatedAmount, prevYearAmount, prevYearDiff, prevYearDiffRate, } = column;
    const isLastSubRow = (breakDownItemIndex + 1) === showingCount;
    const leftSubItemsCount = subItemsCount - showingCount;
    const pathParams = {
      period,
      targetMonth,
      subItems: [
        { dimension: 'sections', itemNames: relatedSections.map(_ => _.name), },
        ...(
          isSubRow ? [{
            dimension,
            itemNames: [subItemName],
          }] : []
        ),
      ],
      singleMonth: aggregationType === 'single' ? '1' : '0',
    };

    return (
      <tr key={itemKey + key} className={classnames({ 'sub-row': isSubRow })}>
        <th className="border-right has-hovered-contents" style={{ width: COLUMN_WIDTH * 2, textIndent: `${hierarchyLevel - 1}rem`, fontWeight: isCategory ? 700 : 400 }} colSpan={5 - hierarchyLevel}>
          <div className="d-flex justify-content-between align-items-start">
            <div>
              {
                dimension !== 'none' && !isSubRow && !isCategory && !isCustom && (
                  <Button size="sm" className="small px-0 mr-1" color="link" onClick={this.toggleSubRows.bind(this, itemKey)}>
                    <span className={classnames('fas cursor-pointer', { 'fa-plus': !shouldShowSubRows, 'fa-minus': shouldShowSubRows })} />
                  </Button>
                )
              }
              <AccountItemDisplay accountItemName={rowName} accountItemSetting={accountItemSetting} iconStyles={{ textIndent: 0 }} isCustom={isCustom} />
              {
                isLastSubRow && leftSubItemsCount > 0 && (
                  <div className="mt-2">
                    <Button size="sm" onClick={this.showMoreSubRows.bind(this, itemKey)}>
                      もっと見る (残り{leftSubItemsCount}件)
                    </Button>
                  </div>
                )
              }
            </div>
          </div>
          {
            !isSummaryColumn && (
              <Fragment>
                <HoveredCommenter
                  ref={(el) => this[`hovered_commenter__${key}`] = el}
                  companyId={companyId}
                  currentUser={user}
                  commenteeKey={commenteeKey}
                  queryKey="sectionBudgets"
                  values={{ yearMonth: targetMonth.toString(), }}
                  comments={commentsGroupedByCommenteeKey[commenteeKey]}
                  users={members}
                  about={['部門予実', targetMonth, itemName, subItemName].filter(_ => _).join(' ')}
                />
                <HoveredNoter companyId={companyId} noteKey={noteKey} pageType="CompanySectionBudgets" queryKey="sectionBudgets" values={{ yearMonth: targetMonth.toString() }} note={notesByKey[noteKey]} writable={canWriteNote(user, role)} />
              </Fragment>
            )
          }
        </th>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            <span>{isFiniteNumber(budgetAmount) ? numeral(budgetAmount).format(displayExpression) : '-'}</span>
          </div>
        </td>
        {
          isComparing && (
            <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
              <div style={{ lineHeight: AMOUNT_HEIGHT }}>
                <span>{isFiniteNumber(comparedBudgetAmount) ? numeral(comparedBudgetAmount).format(displayExpression) : '-'}</span>
              </div>
            </td>
          )
        }
        {
          isComparing && (
            <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
              <div style={{ lineHeight: AMOUNT_HEIGHT }}>
                <span>{isFiniteNumber(diffBetweenBudgets) ? numeral(diffBetweenBudgets).format('+' + displayExpression) : '-'}</span>
              </div>
            </td>
          )
        }
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            {
              (isCategory || isCustom || isSummaryColumn) ? (
                <span>{isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}</span>
              ) : (
                <Link to={encodeURI(`/companies/${companyId}/accountItems/${itemName}?${qs.stringify(pathParams)}`)}>
                  {isFiniteNumber(amount) ? numeral(amount).format(displayExpression) : '-'}
                </Link>
              )
            }
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            {isFiniteNumber(budgetDiff) ? numeral(budgetDiff).format('+' + displayExpression) : '-'}
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            {isFiniteNumber(achievementRate) ? numeral(achievementRate).format('0,0.0%') : '-'}
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            <span>{isFiniteNumber(estimatedAmount) ? numeral(estimatedAmount).format(displayExpression) : '-'}</span>
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            <span>{isFiniteNumber(prevYearAmount) ? numeral(prevYearAmount).format(displayExpression) : '-'}</span>
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            {isFiniteNumber(prevYearDiff) ? numeral(prevYearDiff).format('+' + displayExpression) : '-'}
          </div>
        </td>
        <td className={classnames('text-right')} style={{ width: COLUMN_WIDTH }}>
          <div style={{ lineHeight: AMOUNT_HEIGHT }}>
            {isFiniteNumber(prevYearDiffRate) ? numeral(prevYearDiffRate).format('+0,0.0%') : '-'}
          </div>
        </td>
      </tr>
    );
  }
  sortedCustomSections = () => {
    const { writableCustomSections = [], } = this.props;
    return sortBy(writableCustomSections, _ => _.index != null ? _.index : writableCustomSections.indexOf(_));
  }
  currentCustomSection = () => {
    const { writableCustomSections = [] } = this.props;
    const { customSectionId, } = this.queryParams();
    return writableCustomSections.find(_ => _.id === customSectionId);
  }
  onClickExport = async ({ isAll = false } = {}) => {
    const { writableCustomSections, company, user, period, targetMonth, uniqSectionBudgetContainers, } = this.props;
    const { aggregationType, comparedSectionBudgetContainerId, } = this.queryParams();
    const isComparing = !isEmpty(comparedSectionBudgetContainerId);
    const currentCustomSection = this.currentCustomSection();
    this.setState({ isExporting: true });
    const encoder = new TextEncoder('Shift_JIS', { NONSTANDARD_allowLegacyEncoding: true });
    const rows = isAll ? await (async () => {
      const sectionBudgets = (await Promise.all(chunk(uniqSectionBudgetContainers.map(_ => _.id), 10).map(_ => getCollectionData(company.ref.collection('sectionBudgets').where('sectionBudgetContainerId', 'in', _))))).flat();
      const sectionBudgetsGroupedByClosingDate = groupBy(sectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
      const sectionBudgetsGroupedItemKey = groupBy(sectionBudgets, 'itemKey');
      return writableCustomSections.map((customSection) => {
        return this.generateRows({ customSection, sectionBudgetsGroupedByClosingDate, sectionBudgetsGroupedItemKey, }).map(_ => ({ ..._, customSection }));
      }).flat();
    })() : this.generateRows().map(_ => ({ ..._, customSection: currentCustomSection }));
    const data = rows.map((row) => {
      return {
        aggregationType: aggregationTypes[aggregationType].label,
        yearMonth: targetMonth,
        customSectionName: row.customSection.name,
        ...pick(row, ['itemName', 'subItemName']),
        ...(
          [...keys(omit(metrics, (!isComparing || isAll) && ['comparedBudgetAmount', 'diffBetweenBudgets'])), 'note'].reduce((x, y) => {
            return {
              ...x,
              [y]: row.column[y],
            };
          }, {})
        ),
      };
    });
    const fileContent = encoder.encode(unparseCsv(data));
    fileDownload(fileContent, `${isAll ? '全' : ''}部門予実_${targetMonth}.csv`);
    await log(company, 'monthSectionBudgets', 'exportCsv', user, { period, targetMonth, customSection: currentCustomSection, });
    this.setState({ isExporting: false });
  }
  render() {
    const { user, role, relatedSectionBudgetContainers = [], members = [], comments, history, location, match: { params: { companyId } } } = this.props;
    const { isImporting = false, isExporting = false, sectionTrials, isSettingRows = false, } = this.state;
    const { dimension, customSectionId, comparedSectionBudgetContainerId, } = this.queryParams();
    const isComparing = !isEmpty(comparedSectionBudgetContainerId);
    const [, currentYearMonthItems] = this.monthItems();
    const sectionBudgetContainerOptions = relatedSectionBudgetContainers.map(_ => ({ label: _.name, value: _.id }));
    const customSectionOptions = this.sortedCustomSections().map(_ => ({ label: _.name, value: _.id }));
    const dimensionOptions = entries(dimensions).filter(_ => _[0] !== 'sections').map(([k, v]) => ({ label: v, value: k }));
    const filteredRows = this.filteredRows();

    return (
      <div className="company-period-comparison">
        <div className="p-5">
          <div className="d-flex justify-content-end mb-3">
            <HelpLink text="部門別予実管理を行う" />
          </div>
          <div className="mb-3">
            <h3 className="text-center">部門予実</h3>
          </div>
          <div className="py-3">
            <div className="mb-3 d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-center flex-wrap">
                <QuerySelector className="ml-2" paramName="dimension" width={150} options={dimensionOptions} label="明細" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="customSectionId" width={200} options={customSectionOptions} label="カスタム部門" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="sectionBudgetContainerId" width={200} options={sectionBudgetContainerOptions} label="予算" {...{ history, location }} selectorProps={{ isClearable: false }} />
                <QuerySelector className="ml-2" paramName="comparedSectionBudgetContainerId" width={200} options={sectionBudgetContainerOptions} label="比較予算" {...{ history, location }} />
                <QuerySelector className="ml-2" paramName="aggregationType" width={150} options={aggregationTypeOptions} label="集計種別" {...{ history, location }} selectorProps={{ isClearable: false }} />
              </div>
              <div className="d-flex align-items-end">
                <Button color="secondary" className="ml-2" onClick={this.onClickExport.bind(null, { isAll: true })} disabled={isExporting}>
                  全部門
                  <span className={classnames('ml-1 fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <Button color="secondary" className="ml-2" onClick={this.onClickExport} disabled={isExporting}>
                  <span className={classnames('fas', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
                </Button>
                <CommentsDropdown className="ml-2" companyId={companyId} currentUser={user} comments={comments} usersById={keyBy(members, 'id')} onClickComment={this.onClickComment} />
              </div>
            </div>
            <div className="d-flex justify-content-center position-relative" style={{ zIndex: 0 }}>
              <Table className="sticky-table table-hover" style={{ width: 'auto' }}>
                <thead className="text-center" style={{ lineHeight: '20px' }}>
                  <tr>
                    <th className="border-right" style={{ width: COLUMN_WIDTH * 2 }}>&nbsp;</th>
                    {
                      entries(omit(metrics, !isComparing && ['comparedBudgetAmount', 'diffBetweenBudgets'])).map(([metric, { label: metricLabel, }]) => {
                        return (
                          <th key={metric} className={classnames('text-nowrap')} style={{ width: COLUMN_WIDTH }}>
                            {metricLabel}
                          </th>
                        );
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                    filteredRows.filter(_ => !_.isSubRow || _.shows).map((row) => {
                      return this.renderRow(row);
                    })
                  }
                </tbody>
              </Table>
              <OverlayLoading isOpen={isSettingRows} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CompanyPage(function CompanySectionBudgetsContainer(props) {
  const { targetMonth, location, period, history, match: { params: { companyId } } } = props;
  const { dimension, customSectionId, sectionBudgetContainerId, comparedSectionBudgetContainerId, aggregationType, } = useQueryParams();
  const sectionBudgetContainers = useCollectionSubscription(dimension && companiesRef.doc(companyId).collection('sectionBudgetContainers').where('dimension', '==', dimension).where('period', '==', period), [dimension, period,]);
  const relatedSectionBudgetContainers = orderBy(sectionBudgetContainers.filter(_ => _.customSectionId === customSectionId), _ => _.createdAt.toDate(), 'desc');
  const uniqSectionBudgetContainers = uniqBy(orderBy(sectionBudgetContainers, _ => _.createdAt.toDate(), 'desc'), 'customSectionId');
  const sectionBudgets = useCollectionSubscription(sectionBudgetContainerId && companiesRef.doc(companyId).collection('sectionBudgets').where('sectionBudgetContainerId', '==', sectionBudgetContainerId), [sectionBudgetContainerId]);
  const sectionBudgetsGroupedByClosingDate = groupBy(sectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const sectionBudgetsGroupedItemKey = groupBy(sectionBudgets, 'itemKey');
  const comparedSectionBudgets = useCollectionSubscription(comparedSectionBudgetContainerId && companiesRef.doc(companyId).collection('sectionBudgets').where('sectionBudgetContainerId', '==', comparedSectionBudgetContainerId), [comparedSectionBudgetContainerId]);
  const comparedSectionBudgetsGroupedByClosingDate = groupBy(comparedSectionBudgets, _ => formatDate(_.closingDate, 'YYYYMM'));
  const comparedSectionBudgetsGroupedItemKey = groupBy(comparedSectionBudgets, 'itemKey');
  const comments = useCollectionSubscription(companiesRef.doc(companyId).collection('comments').where('queryKey', '==', 'sectionBudgets').where('yearMonth', '==', targetMonth.toString()).orderBy('createdAt'), [targetMonth]);
  const commentsGroupedByCommenteeKey = groupBy(comments, 'commenteeKey');
  const notes = useCollectionSubscription(companiesRef.doc(companyId).collection('notes').where('queryKey', '==', 'sectionBudgets').where('yearMonth', '==', targetMonth.toString()), [targetMonth]);
  const notesByKey = keyBy(notes, 'noteKey');
  useEffect(() => {
    const path = fullPathWithParams({ dimension: dimension || 'none', aggregationType: aggregationType || 'cumulative', sectionBudgetContainerId: get(relatedSectionBudgetContainers, [0, 'id']), }, location);
    history.replace(encodeURI(path));
  }, [customSectionId, sectionBudgetContainers]);

  return (
    <CompanyMonthSectionBudgets
      {...{
        ...props,
        uniqSectionBudgetContainers,
        relatedSectionBudgetContainers,
        sectionBudgets,
        sectionBudgetsGroupedByClosingDate,
        sectionBudgetsGroupedItemKey,
        comparedSectionBudgets,
        comparedSectionBudgetsGroupedByClosingDate,
        comparedSectionBudgetsGroupedItemKey,
        comments,
        commentsGroupedByCommenteeKey,
        notes,
        notesByKey,
      }}
    />
  );
});
